
import { defineComponent } from 'vue';
import { TranslateResult } from 'vue-i18n';
import slugs from '../../locales/slugs';

export default defineComponent({
  name: 'FooterHome',
  components: {
    GoToTeacherRegistrationDialog: () =>
      import('../../components/GoToTeacherRegistrationDialog.vue'),
  },
  data() {
    return {
      accept: false,
      email: '',
      emailRules: [
        (v: string) =>
          !!v || this.$t('COMMON.ERRORS.EMAIL.EMAIL_REQUIRED').toString(),
        (v: string) => {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return (
            emailPattern.test(v) ||
            this.$t('COMMON.ERRORS.EMAIL.EMAIL_INCORRECT').toString()
          );
        },
      ],
      showDialog: false as boolean,
      locale: this.$root.$i18n,
      slug: slugs as any,
    };
  },
  computed: {
    helpLineNumberCommon(): number {
      const number = this.$t(
        'PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.COMMON_NUMBER',
      );
      return Number(number);
    },
    helpLineNumberTechnical(): number {
      const number = this.$t(
        'PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.TECHNICAL_NUMBER',
      );
      return Number(number);
    },
    tutoreEmail(): TranslateResult {
      return this.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.EMAIL');
    },
    currYear(): TranslateResult {
      const getYear = new Date().getFullYear();
      return this.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ALL_RIGHTS', {
        date: getYear,
      });
    },
  },
  methods: {
    openInNewPage(path: string) {
      if (!path.includes('http')) {
        const route = this.$router.resolve(path);
        window.open(route.href, '_blank');
      } else {
        window.open(path, '_blank');
      }
    },
    checkShowDialog() {
      if (this.$store.state.account.account) {
        this.showDialog = true;
      } else {
        this.$router.push(`${this.localePath('RegisterTeacher')}/`);
      }
    },
    setShowDialog(showDialog: boolean) {
      if (showDialog) {
        this.showDialog = false;
        this.$store.dispatch('account/signOut', 'register');
        if (process.client) {
          localStorage.setItem('profile/profiles', JSON.stringify([]));
          localStorage.removeItem('profile');
          document.cookie = `profile=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${this.$config.websiteUrl.replace(
            'https://',
            '',
          )}`;
        }
        this.$store.commit('profile/setProfiles', []);
        this.$store.commit('profile/setProfile', {});
        this.$router.push(`${this.localePath('RegisterTeacher')}/`);
      } else {
        this.showDialog = false;
      }
    },
  },
});
