var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bc-blueblack-87 c-white",class:{
    'mt-112':
      (_vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.lgAndUp) &&
      _vm.$route.name !== 'Documents',
    'mt-16':
      (_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp) ||
      _vm.$route.name === 'Documents',
  }},[_c('v-container',{class:{
      'px-6': _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
      'px-4': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"fluid":(_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp) ||
      _vm.$vuetify.breakpoint.smAndDown}},[_c('v-row',[_c('v-col',{class:{
          'pt-8 pb-0': _vm.$vuetify.breakpoint.smAndDown,
          'pt-16 pb-0':
            _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"cols":"12","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('p',{staticClass:"w-3",class:{
            'mt-8': _vm.$vuetify.breakpoint.lgAndUp,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.LABEL'))+"\n        ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-about","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.About.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2 mt-4",attrs:{"data-button":"footer-link-about"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.ABOUT'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-documents","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.DocumentsTutoreSmall.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-documents"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.DOC_TUTORE'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-documents","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.DocumentsTutorePoland.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-documents"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.DOC_TUTORE_POLAND'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-help","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.Help.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-help"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.FAQ'))+"\n          ")])]),_vm._v(" "),_c('p',{staticClass:"p3 w-2 c-pointer",attrs:{"data-button":"footer-link-policy"},on:{"click":function($event){return _vm.openInNewPage(
              `${_vm.$config.websiteUrl}/${_vm.$nuxt.$i18n.locale}/polityka-prywatnosci-tutore/`,
            )}}},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.PRIVACY_TUTORE'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2 c-pointer",attrs:{"data-button":"footer-link-policy"},on:{"click":function($event){return _vm.openInNewPage(
              `${_vm.$config.websiteUrl}/${_vm.$nuxt.$i18n.locale}/polityka-prywatnosci-tutore-poland/`,
            )}}},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.PRIVACY_TUTORE_POLAND'))+"\n        ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-contact","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.Help.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-contact"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.CONTACT'))+"\n          ")])]),_vm._v(" "),_c('a',{attrs:{"data-button":"footer-link-store","href":"https://sklep.tutore.eu/"}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-store"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.STORE'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-referral","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.Referral.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",class:{
              'mb-0': _vm.$vuetify.breakpoint.mdAndDown,
            },attrs:{"data-button":"footer-link-referral"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.TUTORE.REFERRAL'))+"\n          ")])])],1),_vm._v(" "),_c('v-col',{class:{
          'pt-6 pb-0': _vm.$vuetify.breakpoint.smAndDown,
          'pt-16 pb-0':
            _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"cols":"12","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('p',{staticClass:"w-3",class:{
            'mt-8': _vm.$vuetify.breakpoint.lgAndUp,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.LABEL'))+"\n        ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-guitar","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer3.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",class:{
              'mt-4':
                _vm.$vuetify.breakpoint.smAndDown ||
                (_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp),
            },attrs:{"data-button":"footer-link-guitar"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.GUITAR'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-math","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer10.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-math"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.MATH'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-programming","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer9.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-programming"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.PROGRAMMING'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-programming","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer13.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-graphic"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.COMPUTER_GRAPHIC'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-drawing","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer14.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-drawing"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.DRAWING'))+"\n          ")])]),_vm._v(" "),_c('nuxt-link',{attrs:{"data-button":"footer-link-english","to":{
            path: `/${_vm.$nuxt.$i18n.locale}${
              _vm.slug.ServiceOffer11.slug[_vm.$nuxt.$i18n.locale]
            }/`,
          }}},[_c('p',{staticClass:"p3 w-2",attrs:{"data-button":"footer-link-english"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.ENGLISH'))+"\n          ")])]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.musicandmore.pl","target":"_blank","rel":"noopener","data-button":"footer-link-camps"}},[_c('p',{staticClass:"p3 w-2",class:{
              'mb-0': _vm.$vuetify.breakpoint.smAndDown,
            },attrs:{"data-button":"footer-link-camps"}},[_vm._v("\n            "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.COURSERS.CAMPS'))+"\n          ")])])],1),_vm._v(" "),_c('v-col',{staticClass:"pt-md-8 pt-lg-3",class:{
          'pt-8 pb-0': _vm.$vuetify.breakpoint.smAndDown,
          'pt-16 pb-0':
            _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"cols":"12","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('p',{staticClass:"w-3 mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.LABEL'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2 mt-4"},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.COMMON'))+"\n          "),_c('a',{attrs:{"href":`tel:${_vm.helpLineNumberCommon}`}},[_vm._v(_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.COMMON_NUMBER_TEXT')))]),_vm._v(" "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.COMMON_HOURS_NORMAL'))+"\n          "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.COMMON_HOURS_WEEKEND'))+"\n          "),_c('br')]),_vm._v(" "),_c('p',{staticClass:"p3 w-2"},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.TECHNICAL'))+"\n          "),_c('a',{attrs:{"href":`tel:${_vm.helpLineNumberTechnical}`}},[_vm._v(_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.TECHNICAL_NUMBER_TEXT')))]),_vm._v(" "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.TECHNICAL_HOURS_NORMAL'))+"\n          "),_c('br')]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mb-0':
              _vm.$vuetify.breakpoint.smAndDown ||
              (_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp),
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.EMAIL_TEXT'))+"\n          "),_c('a',{attrs:{"href":`mailto:${_vm.tutoreEmail}`}},[_vm._v(_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.SUPPORT.EMAIL')))])])]),_vm._v(" "),_c('v-col',{class:{
          'pt-8 pb-0': _vm.$vuetify.breakpoint.smAndDown,
          'pt-16 pb-0':
            _vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"cols":"12","lg":"3","md":"3","sm":"12","xs":"12"}},[_c('p',{staticClass:"w-3 mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.LABEL'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mt-4': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.COMPANY'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mb-0': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.ADDRESS'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mb-0': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.CITY'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mb-0': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.NIP'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"p3 w-2",class:{
            'mb-0': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.ADDRESS.KRS'))+"\n        ")])]),_vm._v(" "),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col'):_vm._e()],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',{staticClass:"w-3 mt-8"},[_vm._v("\n      "+_vm._s(_vm.$t('PAGES.HOMEPAGE.BANNER.FOOTER.PAYMENT'))+"\n    ")]),_vm._v(" "),_c('v-row',{staticClass:"mb-8 mt-1 dif ml-0 w100"},[(_vm.$vuetify.breakpoint.xlOnly)?_c('div',{staticClass:"cursor-auto w100",attrs:{"title":"Obsługujemy płatności internetowe przez system płatności online Tpay"}},[_c('v-img',{staticClass:"w100",staticStyle:{"border":"0"},attrs:{"src":"https://tpay.com/img/banners/tpay-full-1000x51.svg","alt":"Logo Tpay","title":"Logo Tpay"}})],1):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"cursor-auto",staticStyle:{"width":"100%"},attrs:{"title":"Obsługujemy płatności internetowe przez system płatności online Tpay"}},[_c('v-img',{staticStyle:{"border":"0","width":"100%"},attrs:{"src":"https://tpay.com/img/banners/tpay-full-300x203.svg","alt":"Logo Tpay","title":"Logo Tpay"}})],1):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.lgAndDown && !_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"cursor-auto w100",attrs:{"title":"Obsługujemy płatności internetowe przez system płatności online Tpay"}},[_c('v-img',{attrs:{"src":"https://tpay.com/img/banners/tpay-768x90.svg","max-width":"100%","alt":"Logo Tpay","title":"Logo Tpay"}})],1):_vm._e()]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('v-row',{staticClass:"mt-8 mb-8",staticStyle:{"width":"100%"},style:(_vm.$vuetify.breakpoint.smAndDown &&
        'display: flex; flex-direction: column-reverse; align-items: center; width: 105%;')},[_c('v-col',{staticClass:"df",class:{
          'pt-0':
            _vm.$vuetify.breakpoint.smAndDown ||
            (_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp),
        },staticStyle:{"align-items":"center"},style:(_vm.$vuetify.breakpoint.smAndDown && 'justify-content: center'),attrs:{"md":"5"}},[_c('p',{staticClass:"p3",class:{
            'mb-0': _vm.$vuetify.breakpoint.smAndUp,
            'mb-8': _vm.$vuetify.breakpoint.smAndDown,
          }},[_vm._v("\n          "+_vm._s(_vm.currYear)+"\n        ")])]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{staticClass:"df",class:{
          'pt-0':
            _vm.$vuetify.breakpoint.smAndDown ||
            (_vm.$vuetify.breakpoint.smAndUp && !_vm.$vuetify.breakpoint.lgAndUp),
        },staticStyle:{"flex-direction":"row","justify-content":"center"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"df"},[_c('a',{attrs:{"href":"https://www.facebook.com/tutore.eu","target":"_blank","rel":"noopener","data-button":"footer-link-facebook","aria-label":"Facebook"}},[_c('v-img',{staticClass:"ma-2",attrs:{"data-button":"footer-link-facebook","src":require('@/assets/img/icons/facebook.svg'),"width":"40","height":"40","alt":"Facebook"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.youtube.com/c/szkolaMM","target":"_blank","rel":"noopener","data-button":"footer-link-youtube","aria-label":"Youtube"}},[_c('v-img',{staticClass:"ma-2",attrs:{"data-button":"footer-link-youtube","src":require('@/assets/img/icons/youtube.svg'),"width":"40","height":"40","alt":"YouTube"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.tiktok.com/@tutore_edu","target":"_blank","rel":"noopener","data-button":"footer-link-tiktok","aria-label":"Tiktok"}},[_c('v-img',{staticClass:"ma-2",attrs:{"data-button":"footer-link-tiktok","src":require('@/assets/img/icons/tik-tok.svg'),"width":"40","height":"40","alt":"TikTok"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.instagram.com/tutore_edu/","target":"_blank","rel":"noopener","data-button":"footer-link-instagram","aria-label":"Instagram"}},[_c('v-img',{staticClass:"ma-2",attrs:{"data-button":"footer-link-instagram","src":require('@/assets/img/icons/instagram.svg'),"width":"40","height":"40","alt":"Instagram"}})],1)])],1)],1)],1),_vm._v(" "),(_vm.showDialog)?_c('go-to-teacher-registration-dialog',{on:{"setShowDialog":(showDialog) => showDialog(showDialog)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }