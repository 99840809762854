
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
  name: 'SafeHtml',
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const sanitizedHtml = ref('');

    const sanitizeContent = async (content: string) => {
      const DOMPurify = await import('dompurify');
      sanitizedHtml.value = DOMPurify.sanitize(content);
    };

    watch(
      () => props.htmlContent,
      (newContent) => {
        sanitizeContent(newContent);
      },
      { immediate: true },
    );

    return {
      sanitizedHtml,
    };
  },
});
