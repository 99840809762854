var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{style:(`display: ${_vm.$vuetify.breakpoint.mdAndUp ? '' : 'none'};`)},[_c('v-col',{staticStyle:{"margin-top":"32px"},attrs:{"cols":"12"}},[_c('bread-crumb',{staticClass:"c-error-page",attrs:{"path":_vm.breadCrumb,"current":_vm.currentPage}})],1)],1),_vm._v(" "),_c('v-row',{style:(`display: flex; flex-direction: ${
          _vm.$vuetify.breakpoint.mdAndUp ? 'row' : 'column-reverse'
        }; justify-content: center; align-items: center;`)},[_c('v-col',{style:(`text-align: ${
            _vm.$vuetify.breakpoint.mdAndUp ? 'left' : 'center'
          };`),attrs:{"xl":"6","lg":"6","md":"6","cols":"12"}},[_c('span',{staticClass:"title-oops"},[_vm._v(" Oops! ")]),_vm._v(" "),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"16px"}},[_c('span',{staticClass:"subtitle-cant-find"},[_vm._v("\n                "+_vm._s(_vm.$t('PAGES.NOT_FOUND.CANT_FIND'))+","),_c('br'),_vm._v(_vm._s(_vm.$t('PAGES.NOT_FOUND.LOOKING_FOR'))+".\n              ")])])],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('span',{staticClass:"code-error-404"},[_vm._v("\n                "+_vm._s(_vm.$t('PAGES.NOT_FOUND.ERROR_CODE'))+"\n              ")])])],1),_vm._v(" "),_c('v-row',[_c('v-col',{class:`${
                _vm.$vuetify.breakpoint.mdAndUp ? 'pt-5 mt-0' : 'pa-6 ma-0'
              };`},[_c('t-btn',{style:(`width: ${
                  _vm.$vuetify.breakpoint.mdAndUp ? '134px' : '100%'
                };`),attrs:{"color":"primary","data-button":"not-found-btn-home","href":`${_vm.$config.websiteUrl}/${_vm.$nuxt.$i18n.locale}${
                  _vm.slug.Home.slug[_vm.$nuxt.$i18n.locale]
                }/`}},[_vm._v("\n                "+_vm._s(_vm.$t('PAGES.NOT_FOUND.MAIN_PAGE'))+"\n              ")]),_vm._v(" "),_c('t-btn',{style:(`width: ${
                  _vm.$vuetify.breakpoint.mdAndUp ? '134px' : '100%'
                }; margin-left: ${
                  _vm.$vuetify.breakpoint.mdAndUp ? '16px' : '0'
                }; margin-top: ${_vm.$vuetify.breakpoint.mdAndUp ? '0' : '16px'}`),attrs:{"color":"secondary","data-button":"not-found-btn-reload"},on:{"click":_vm.reloadPage}},[_vm._v("\n                "+_vm._s(_vm.$t('PAGES.NOT_FOUND.REFRESH_PAGE'))+"\n              ")])],1)],1)],1),_vm._v(" "),_c('v-col',{staticStyle:{"margin-top":"36px"},attrs:{"xl":"6","lg":"6","md":"6","cols":"12"}},[_c('v-img',{attrs:{"src":require(_vm.$vuetify.breakpoint.mdAndUp
                ? '@/assets/img/error_404.png'
                : '@/assets/img/error_404_phone.png')}})],1)],1)],1)],1),_vm._v(" "),_c('home-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }