import { AccountState } from './stateType';

export default (): AccountState => ({
  account: undefined,
  isAccountData: false,
  activationError: false,
  activationSuccess: false,
  isTutor: false,
  isTutorCompleteRegistration: false,
});
