var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bread-crumbs"},[(_vm.path && _vm.path.length)?_c('ul',{staticClass:"bread-crumbs",attrs:{"set":(_vm.tmpCurrent = _vm.current)}},[_vm._l((_vm.breadCrumb),function(item,index){return _c('li',{key:index,attrs:{"set":(_vm.tmpCurrent = item.error ? '' : _vm.tmpCurrent),"data-id":index,"data-item":item.name,"data-button":"breadcrumb-link"}},[(
          (_vm.tmpCurrent || (!_vm.tmpCurrent && index !== _vm.breadCrumb.length - 1)) &&
          item.path !== '/'
        )?_c('nuxt-link',{attrs:{"to":item.path,"data-id":index,"data-item":item.name,"data-button":"breadcrumb-link","active-class":"active"}},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")]):(
          (_vm.tmpCurrent || (!_vm.tmpCurrent && index !== _vm.breadCrumb.length - 1)) &&
          item.path === '/'
        )?_c('a',{attrs:{"href":`${_vm.$config.websiteUrl}/${_vm.$nuxt.$i18n.locale}${
          _vm.slug.Home.slug[_vm.$nuxt.$i18n.locale]
        }/`,"data-id":index,"data-item":item.name,"data-button":"breadcrumb-link","active-class":"active"}},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")]):[_vm._v("\n        "+_vm._s(item.name)+"\n      ")]],2)}),_vm._v(" "),(_vm.tmpCurrent)?_c('li',[_vm._v("\n      "+_vm._s(_vm.tmpCurrent)+"\n    ")]):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }