import { ProfileState } from './stateType';

export default (): ProfileState => ({
  profileId: 0,
  profile: {},
  profiles: [],
  enrollProfile: {},
  enrollParent: {},
  password: '',
});
