export default async function ({
  app,
  route,
  redirect,
}: {
  app: any;
  route: any;
  redirect: any;
}) {
  await app.i18n.locales.filter(
    (i: { code: any }) => i.code !== app.i18n.locale,
  );
  const path = await route.fullPath;
  const lastStr = path.toString().split('/').pop();
  const trailingSlash = lastStr !== '' && !lastStr.includes('?');

  if (path === '/' || path === `/${app.i18n.locale}/`) {
    await redirect({ path: `/${app.i18n.locale}/login/` });
  }

  if (trailingSlash) {
    await redirect({ path: `${path}/` });
  }
}
