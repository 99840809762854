import { MutationTree } from 'vuex/types/index';
import { set } from '../../utils/vuex';
import { AccountState } from './stateType';

const mutations: MutationTree<AccountState> = {
  setAccount: set<AccountState>('account'),
  setIsAccountData: set('isAccountData'),
  setActivationError: set('activationError'),
  setActivationSuccess: set('activationSuccess'),
  setIsTutor: set('isTutor'),
  setIsTutorCompleteRegistration: set('isTutorCompleteRegistration'),
};

export default mutations;
