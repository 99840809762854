
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DialogCard',
  data() {
    return {
      dialog: true,
    };
  },
});
