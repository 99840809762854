import { ActionContext, ActionTree } from 'vuex';
import { RootStateStore } from '../rootState';
import { InformationsState } from './stateType';

const actions: ActionTree<InformationsState, RootStateStore> = {
  async fetchInformations({
    commit,
  }: ActionContext<InformationsState, unknown>): Promise<void> {
    try {
      const informations = await this.$http
        .get('/tutors/informations')
        .then((response) => response.data);
      return commit('setInformations', informations);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};

export default actions;
