
import { defineComponent } from 'vue';
import CookieAccordion from './CookieAccordion.vue';
import cookiesArray from './cookies';

export default defineComponent({
  name: 'CookieDialog',
  components: {
    CookieAccordion,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    consentsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          title: 'title',
          text: 'text',
        },
      ],
      cookiesArray,
      customConsents: this.consentsData,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    setConsents($event) {
      this.customConsents = { ...this.consentsData, ...$event };
    },
  },
});
