
import { defineComponent } from 'vue';
import HomeFooter from '../components/home/Footer.vue';
import BreadCrumb from '../components/dashboard/BreadCrumb.vue';
import { BreadCrumbPosition } from '../types/enum';
import { BreadcrumbInt } from '../types/interfaces';
import slugs from '~/locales/slugs';

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    HomeFooter,
    BreadCrumb,
  },
  data() {
    const breadCrumb: BreadcrumbInt[][] = [[]];

    breadCrumb[BreadCrumbPosition.MAIN].push({
      name: this.$t('PAGES.NOT_FOUND.MAIN_PAGE').toString(),
      path: '/',
    });
    return {
      breadCrumb,
      currentPage: this.$t('PAGES.NOT_FOUND.PAGE_NOT_FOUND').toString(),
      slug: slugs as any,
    };
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
});
