import CombinedVueInstance, { CreateComponentPublicInstance } from 'vue';
import { DateTime } from 'ts-luxon';
import { Inject } from '@nuxt/types/app';
import {
  LessonInt,
  SideBarItemsInt,
  KeyValueInt,
  PersonInt,
  Notification,
  CourseInt,
  Tags,
  School,
  Quiz,
  ServiceStrapi,
  TimerInformation,
  Media,
  StepDescriptionType,
  Availability,
  Subpart,
  LessonData,
  WebsiteSlugInt,
} from '../types/interfaces';
import {
  ProfileType,
  FileExtToType,
  Video,
  Enum,
  TIMEZONE,
  StepDescriptionStatus,
  CourseType,
  PartType,
  DATE_FORMAT,
} from '../types/enum';
import sideBarsItems from '../store/sideBarItems';
import {
  TimeToEnterLesson,
  TimeToEnterLessonTeacher,
  PriceFormat,
} from '../types/configuration';
import { LessonMaterials } from '../types/backend/models';
import { Account } from '../store/account/stateType';
import { courseCardsMock } from '../mock/dashboardClientCards';
import WebsiteSlugs from '../locales/websiteSlugs';

const helpers = (context: any, inject: Inject) => {
  const Variants = (l: number, t: string[]): string => {
    let ret = '';
    const [one, two, tree] = t;
    if (l === 1) {
      ret = one;
    } else if (l % 10 > 1 && l % 10 < 5 && !(l % 100 >= 10 && l % 100 <= 21)) {
      ret = two;
    } else {
      ret = tree;
    }
    return ret;
  };

  const EPOCHS = ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'];

  const DURATION_IN_SECONDS: {
    [name: string]: number;
  } = {
    YEAR: 31536000,
    MONTH: 2592000,
    DAY: 86400,
    HOUR: 3600,
    MINUTE: 60,
    SECOND: 1,
  };

  const TimeSince = (
    dateEnd: Date,
    dateStart = new Date(),
  ): {
    interval: number;
    epoch: string;
  } => {
    const currentDate: number = new Date(dateStart).getTime();
    const selectedDate: number = new Date(dateEnd).getTime();
    let epoch = 'SECOND';
    let interval = 0;

    let seconds = Math.floor((currentDate - selectedDate) / 1000);
    if (Number.isNaN(seconds)) {
      return {
        interval,
        epoch,
      };
    }

    const isFuture = seconds <= 1;
    seconds *= isFuture ? -1 : 1;

    for (let i = 0; i < EPOCHS.length; i += 1) {
      epoch = EPOCHS[i];
      interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
      if (interval >= 1) {
        interval *= isFuture ? -1 : 1;
        return {
          interval,
          epoch,
        };
      }
    }
    return {
      interval,
      epoch,
    };
  };

  const LeadingZero = (number: number): string => `0${number}`.slice(-2);

  const DateOperations = (
    date: Date,
    day = 0,
    month = 0,
    year = 0,
    resetTime = true,
  ): Date => {
    if (resetTime) {
      date.setHours(0, 0, 0, 0);
    }
    date.setDate(date.getDate() + day);
    date.setMonth(date.getMonth() + month);
    date.setFullYear(date.getFullYear() + year);
    return date;
  };

  const ParseDateToTimeSince = (date: Date): string => {
    let tmp = '';
    const ts = TimeSince(date);
    const stringDate = DateOperations(new Date(date)).valueOf();

    let str = '';
    if (stringDate === DateOperations(new Date(), 1).valueOf()) {
      str = 'TOMORROW';
    } else if (stringDate === DateOperations(new Date(), -1).valueOf()) {
      str = 'YESTERDAY';
    } else if (stringDate === DateOperations(new Date()).valueOf()) {
      str = 'TODAY';
    }

    if (str.length) {
      tmp = context.app.i18n.t(`COMMON.TIME.${str}`).toString();
    } else if (ts.interval < 0) {
      tmp = ` ${context.app.i18n.t('COMMON.TIME.BEHIND')} ${
        ts.interval * -1
      } ${Variants(ts.interval, [
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.1`).toString(),
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.2`).toString(),
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.3`).toString(),
      ])}`;
    } else {
      tmp = `${ts.interval} ${Variants(ts.interval, [
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.1`).toString(),
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.2`).toString(),
        context.app.i18n.t(`COMMON.TIME.${ts.epoch}.3`).toString(),
      ])} ${context.app.i18n.t('COMMON.TIME.AGO')}`;
    }
    return tmp;
  };

  const ParseDate = (date: Date, shortMonth = false): string => {
    const currentYear = new Date().getFullYear();
    const normalizedDate = new Date(date);
    const year = normalizedDate.getFullYear();

    return `${normalizedDate.getDate()} ${context.app.i18n
      .t(
        `COMMON.MONTHS${shortMonth ? '_SHORT' : ''}.${
          normalizedDate.getMonth() + 1
        }`,
      )
      .toString()}${currentYear !== year ? ` ${year}` : ''}, ${LeadingZero(
      normalizedDate.getHours(),
    )}:${LeadingZero(normalizedDate.getMinutes())}`;
  };

  const GetDate = (
    date: Date | string,
    shortMonth = false,
    year = true,
    day = true,
    months = false,
  ): string => {
    const normalizedDate = new Date(date);

    return `${day ? normalizedDate.getDate() : ''} ${context.app.i18n
      .t(
        `COMMON.MONTHS${months ? '2' : ''}${shortMonth ? '_SHORT' : ''}.${
          normalizedDate.getMonth() + 1
        }`,
      )
      .toString()} ${year ? normalizedDate.getFullYear() : ''}`;
  };

  const GetTime = (date: Date | string, timeFormat?: string): string => {
    const normalizedDate = new Date(date);
    const is12HourFormat = timeFormat === '12';
    return is12HourFormat
      ? FormatTimeToAmPm(normalizedDate, true)
      : LeadingZero(normalizedDate.getHours()) +
          ':' +
          LeadingZero(normalizedDate.getMinutes());
  };

  const GenerateLessonDate = (date: Date, timeFormat?: string): string[] => {
    const tmp = [];
    const normalizedDate = new Date(date);
    const stringDate = DateOperations(new Date(date)).valueOf();

    let str = '';
    if (stringDate === DateOperations(new Date(), 1).valueOf()) {
      str = 'TOMORROW';
    } else if (stringDate === DateOperations(new Date(), -1).valueOf()) {
      str = 'YESTERDAY';
    } else if (stringDate === DateOperations(new Date()).valueOf()) {
      str = 'TODAY';
    }

    if (str.length) {
      tmp[0] = context.app.i18n.t(`COMMON.TIME.${str}`).toString();
    } else {
      tmp[0] = GetDate(normalizedDate, true, false);
      tmp[1] = context.app.i18n
        .t(`COMMON.DAYS2.${normalizedDate.getDay()}`)
        .toString();
    }
    tmp[2] = GetTime(normalizedDate, timeFormat);
    return tmp;
  };

  const GetMonday = (date: Date, weeks = 0): Date => {
    let normalizedDate = new Date(date.setHours(0, 0, 0));
    const day = normalizedDate.getDay();
    const diff = normalizedDate.getDate() - day + (day === 0 ? -6 : 1);
    normalizedDate = new Date(normalizedDate.setDate(diff));
    return new Date(
      normalizedDate.setDate(normalizedDate.getDate() + weeks * 7),
    );
  };

  const formatLessonTime = (date: string): string =>
    new Date(date).toLocaleTimeString('pl-PL', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

  const sortByDate = (lessons: LessonInt[], sortDesc = false): LessonInt[] => {
    const tmp = lessons.sort(
      (a: LessonInt, b: LessonInt) =>
        new Date(a.start).getTime() - new Date(b.start).getTime(),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  };

  const getTodayLessons = (lessons: LessonInt[], count = 2): LessonInt[] => {
    const now = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return (lessons || [])
      .filter((e) => {
        const se = new Date(e.endMainTimezone || e.end);
        return se > now && se < tomorrow;
      })
      .slice(0, count);
  };
  const getNextLesson = (
    lessons: LessonInt[],
    count = 2,
    sortDesc = false,
  ): LessonInt[] => {
    const now = new Date();
    return sortByDate(lessons || [], sortDesc)
      .filter((e) => {
        const sd = new Date(e.endMainTimezone || e.end);
        return sd > now;
      })
      .slice(0, count);
  };
  function getNextLessonNotStarted(
    lessons: LessonInt[],
    count = 2,
    sortDesc = false,
  ): LessonInt[] {
    const now = new Date();
    const sortedLessons = sortLessonByDate(lessons, sortDesc);

    const ongoingLessons = sortedLessons.filter((e) => {
      const startDateTime = new Date(e.startMainTimezone || e.start);
      const endDateTime = new Date(e.endMainTimezone || e.end);
      return startDateTime <= now && now <= endDateTime;
    });

    const notStartedLessons = sortedLessons.filter(
      (e) => new Date(e.startMainTimezone || e.start) > now,
    );

    const result = [...ongoingLessons, ...notStartedLessons].slice(0, count);

    return result;
  }
  const getPrevLesson = (
    lessons: LessonInt[],
    count = 2,
    sortDesc = false,
  ): LessonInt[] => {
    const now = new Date();
    return sortByDate(lessons || [], sortDesc)
      .filter((e) => {
        const sd = new Date(e.start);
        return sd < now;
      })
      .slice(0, count);
  };
  const getLessonLastWeek = (lessons: LessonInt[]): LessonInt[] => {
    const now = new Date();
    const monday = GetMonday(new Date(), -1);
    return (lessons || []).filter((e) => {
      const sd = new Date(e.end);
      return monday <= sd && sd < now;
    });
  };
  const getLessonThisWeek = (lessons: LessonInt[]): LessonInt[] => {
    const now = new Date();
    const nextMonday = GetMonday(new Date(), 1);
    return (lessons || []).filter((e) => {
      const sd = new Date(e.start);
      return sd > now && sd < nextMonday;
    });
  };
  const getLessonNextWeek = (lessons: LessonInt[]): LessonInt[] => {
    const nextMonday = GetMonday(new Date(), 1);
    const secondMonday = GetMonday(new Date(), 2);
    return (lessons || []).filter((e) => {
      const sd = new Date(e.start);
      return sd > nextMonday && sd < secondMonday;
    });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  const DeepCopy = (obj: unknown) => JSON.parse(JSON.stringify(obj));

  type VueComponentInstance =
    | CombinedVueInstance
    | CreateComponentPublicInstance;

  const TunnelEmitter = (
    _t: VueComponentInstance,
    event: string,
    ...payload: unknown[]
  ): boolean => {
    let vm = _t;
    while (vm && !vm.$listeners[event]) {
      vm = vm.$parent as VueComponentInstance;
    }
    if (!vm) {
      return false;
    }
    vm.$emit(event, ...payload);
    return true;
  };

  const getSideBarItems = (
    menuKey: string,
    data: KeyValueInt = {},
  ): SideBarItemsInt[] => {
    // eslint-disable-next-line no-param-reassign
    menuKey +=
      menuKey === 'client_individual' && data.profileId ? '_STUDENT' : '';
    const menu = DeepCopy(sideBarsItems[menuKey] || []);
    // const isProd = window.location.hostname === 'tutore.eu';
    const menuTmp = menu;
    menuTmp.forEach((item: SideBarItemsInt) => {
      const tmp = item;
      tmp.path = item.path.replace(
        /\$\(([^\\)]+)?\)/g,
        (_$1, $2) => data[$2] as string,
      );
      if (tmp.path.includes('/profile/undefined/')) {
        tmp.path = tmp.path.replace('/profile/undefined/', '/my-profile/');
      }
    });
    return menuTmp;
  };

  const getBaseUrl = (_t: VueComponentInstance): string => {
    let baseUrl = '';
    _t.$route.matched
      .slice()
      .reverse()
      .forEach((r) => {
        if (!baseUrl) {
          baseUrl = r.meta.baseUrl;
        }
      });
    const resolved = _t.$router.resolve({
      name: baseUrl,
      params: _t.$route.params,
    });
    return resolved.href;
  };

  const capitalize = (text: string): string => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return '';
  };

  const checkIfEnterToLessonEnabled = (
    start: Date | string,
    end: Date | string,
    attributes: KeyValueInt,
    role: string,
  ): boolean => {
    const tNow = new Date().valueOf();
    const tStart = new Date(start).valueOf();
    const tEnd = new Date(end).valueOf();
    const ended = tEnd < tStart;
    if (!attributes.cancelled && !attributes.ended && !ended) {
      const minutes = Math.floor((tStart - tNow) / 1000 / 60);
      if (
        (minutes > 0 &&
          minutes <=
            (role === ProfileType.TEACHER
              ? TimeToEnterLessonTeacher
              : TimeToEnterLesson)) ||
        (tNow > tStart && tNow < tEnd)
      ) {
        return true;
      }
    }
    return false;
  };

  const checkRateLessonEnabled = (
    role: string,
    attributes: KeyValueInt,
    student: PersonInt,
  ): boolean =>
    student &&
    !attributes.cancelled &&
    !!attributes.ended &&
    ((role === ProfileType.TEACHER && !attributes.tutorRated) ||
      (role !== ProfileType.TEACHER &&
        !Object.values(student.rate || {}).filter((r) => r).length &&
        !(student.absence === true || student.absence === null)));
  // role !== ProfileType.TEACHER && !attributes.studentRated && !student.absence

  const calculateLessonRate = (
    role: string,
    attributes: KeyValueInt,
    student: PersonInt,
    tutor: PersonInt,
  ): number => {
    let rating = 0;
    if (role === ProfileType.TEACHER && attributes.tutorRated && tutor.rate) {
      rating = (tutor.rate.materialsRating + tutor.rate.connectionRating) / 2;
    } else if (
      role !== ProfileType.TEACHER &&
      student &&
      Object.values(student.rate || {}).filter((r) => r).length
    ) {
      // } else if (role !== ProfileType.TEACHER && attributes.studentRated) {
      if (student && student.rate) {
        if (student.rate.materialsRating) {
          rating =
            (student.rate.tutorRating +
              student.rate.materialsRating +
              student.rate.connectionRating) /
            3;
        } else {
          rating =
            (student.rate.tutorRating + student.rate.connectionRating) / 2;
        }
      }
    }
    return Math.round(rating * 10) / 10;
  };

  const primarySchoolGrade = [
    {
      display: '1',
      value: 1,
    },
    {
      display: '2',
      value: 2,
    },
    {
      display: '3',
      value: 3,
    },
    {
      display: '4',
      value: 4,
    },
    {
      display: '5',
      value: 5,
    },
    {
      display: '6',
      value: 6,
    },
    {
      display: '7',
      value: 7,
    },
    {
      display: '8',
      value: 8,
    },
  ];

  const highSchoolGrade = [
    {
      display: '1',
      value: 9,
    },
    {
      display: '2',
      value: 10,
    },
    {
      display: '3',
      value: 11,
    },
    {
      display: '4',
      value: 12,
    },
    {
      display: '5',
      value: 13,
    },
  ];

  const parseCookie = (name: string, str: string): string => {
    if (!str) {
      return '';
    }
    const parsed = Object.fromEntries(
      str.split('; ').map((x: string) => x.split('=')),
    );
    return name ? parsed[name] : parsed;
  };

  const setCookie = (
    name: string,
    str: string,
    time: Date | undefined,
  ): void => {
    const d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${str};expires=${(
      time || d
    ).toUTCString()};path=/`;
  };

  const formatPrice = (price: number): string =>
    Intl.NumberFormat(PriceFormat, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price || 0);

  const allowBoldTag = (str: string): string =>
    str
      .toString()
      .replace(/&lt;b&gt;/g, '<b>')
      .replace(/&lt;\/b&gt;/g, '</b>');

  const toBold = (text: string): string =>
    text.replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
  const toNewLine = (text: string): string =>
    text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const timeGrid = [
    { startTime: '15:00:00', endTime: '16:00:00', dayOfWeek: 'WT' },
    { startTime: '16:10:00', endTime: '17:10:00', dayOfWeek: 'WT' },
    { startTime: '17:20:00', endTime: '18:20:00', dayOfWeek: 'WT' },
    { startTime: '18:30:00', endTime: '19:30:00', dayOfWeek: 'WT' },
    { startTime: '19:40:00', endTime: '20:40:00', dayOfWeek: 'WT' },
    { startTime: '15:00:00', endTime: '16:00:00', dayOfWeek: 'ŚR' },
    { startTime: '16:10:00', endTime: '17:10:00', dayOfWeek: 'ŚR' },
    { startTime: '17:20:00', endTime: '18:20:00', dayOfWeek: 'ŚR' },
    { startTime: '18:30:00', endTime: '19:30:00', dayOfWeek: 'ŚR' },
    { startTime: '19:40:00', endTime: '20:40:00', dayOfWeek: 'ŚR' },
    { startTime: '15:00:00', endTime: '16:00:00', dayOfWeek: 'CZ' },
    { startTime: '16:10:00', endTime: '17:10:00', dayOfWeek: 'CZ' },
    { startTime: '17:20:00', endTime: '18:20:00', dayOfWeek: 'CZ' },
    { startTime: '18:30:00', endTime: '19:30:00', dayOfWeek: 'CZ' },
    { startTime: '19:40:00', endTime: '20:40:00', dayOfWeek: 'CZ' },
    { startTime: '15:00:00', endTime: '16:00:00', dayOfWeek: 'PT' },
    { startTime: '16:10:00', endTime: '17:10:00', dayOfWeek: 'PT' },
    { startTime: '17:20:00', endTime: '18:20:00', dayOfWeek: 'PT' },
    { startTime: '18:30:00', endTime: '19:30:00', dayOfWeek: 'PT' },
    { startTime: '19:40:00', endTime: '20:40:00', dayOfWeek: 'PT' },
    { startTime: '09:00:00', endTime: '10:00:00', dayOfWeek: 'SB' },
    { startTime: '10:10:00', endTime: '11:10:00', dayOfWeek: 'SB' },
    { startTime: '11:20:00', endTime: '12:20:00', dayOfWeek: 'SB' },
    { startTime: '12:30:00', endTime: '13:30:00', dayOfWeek: 'SB' },
    { startTime: '15:00:00', endTime: '16:00:00', dayOfWeek: 'PN' },
    { startTime: '16:10:00', endTime: '17:10:00', dayOfWeek: 'PN' },
    { startTime: '17:20:00', endTime: '18:20:00', dayOfWeek: 'PN' },
    { startTime: '18:30:00', endTime: '19:30:00', dayOfWeek: 'PN' },
    { startTime: '19:40:00', endTime: '20:40:00', dayOfWeek: 'PN' },
  ];

  const generateFileType = (fileName: string): string | undefined => {
    if (fileName) {
      const ext = fileName.split('.').pop();
      // @ts-ignore
      if (ext && FileExtToType[ext]) {
        // @ts-ignore
        return FileExtToType[ext];
      }
      return '';
    }
    return undefined;
  };

  const videoParser = (url: string): Video | undefined => {
    const regExp =
      /((youtu\.be\/)|((youtube.com\/embed\/)|(youtube.com\/shorts\/)|(youtube.com\/watch\??v?=?))|(vimeo\.com\/video\/)|(vimeo\.com\/))([^#&?]{9,11})/;
    const video: Video = { id: undefined, url: '', thumb: '' };

    if (url && url.match(regExp)?.[9]) {
      if (url.includes('vimeo')) {
        video.id = url.match(regExp)?.[9];
        video.url = `https://player.vimeo.com/video/${video.id}`;
        video.thumb = `https://vumbnail.com/${video.id}.jpg`;
        return video;
      }
      if (url.includes('youtu') || url.includes('youtube')) {
        video.id = url.match(regExp)?.[9];
        video.url = `https://www.youtube.com/embed/${video.id}?autoplay=1`;
        video.thumb = `https://img.youtube.com/vi/${video.id}/0.jpg`;
        return video;
      }
    }

    return undefined;
  };

  const generateSpecializationType = (grades: number[]): string => {
    const isForAdult = grades?.filter((x) => x === 99).length > 0;
    const isForChildren = grades?.filter((x) => x === 1).length > 0;

    if (isForAdult && isForChildren) {
      return context.app.i18n.t('COURSE.SPECIALIZATION.ALL');
    }
    if (isForAdult) {
      return context.app.i18n.t('COURSE.SPECIALIZATION.ADULTS');
    }
    if (isForChildren) {
      return context.app.i18n.t('COURSE.SPECIALIZATION.KIDS');
    }
    return '';
  };

  const generateFormattedDate = (
    date: string,
    shortcutMonthsName = false,
  ): string => {
    const dateArray = date.split('-');
    if (shortcutMonthsName) {
      const monthNumber =
        Number(dateArray[1]) < 10 ? dateArray[1][1] : dateArray[1];
      return `${dateArray[2]} ${context.app.i18n.t(
        `COMMON.MONTHS_SHORT.${monthNumber}`,
      )} ${dateArray[0]}`;
    }
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  };

  const sortNotificationsByDate = (
    notifications: Notification[],
    sortDesc = false,
  ): Notification[] => {
    const tmp = notifications.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  };

  const generateNumber = (number: number): string =>
    number < 10 ? `0${number}` : number.toString();

  const countryCodesForOnboarding = [
    'BE',
    'CA',
    'CN',
    'HR',
    'CZ',
    'DK',
    'FR',
    'DE',
    'IN',
    'ID',
    'IE',
    'IT',
    'JP',
    'LT',
    'LU',
    'NL',
    'PH',
    'PL',
    'PT',
    'RO',
    'RU',
    'SK',
    'ES',
    'SE',
    'CH',
    'TW',
    'TH',
    'TR',
    'UA',
    'GB',
    'US',
    'VN',
  ];

  const generateKeyByValue = (e: Enum, value: string): string => {
    const indexOfCountryDialCode = Object.values(e).indexOf(value);
    return Object.keys(e)[indexOfCountryDialCode];
  };

  function hasOwnProperty<T, K extends PropertyKey>(
    obj: T,
    prop: K,
  ): obj is T & Record<K, unknown> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
  }

  function checkTimezone(): string | null {
    return DateTime.local().zoneName;
  }

  function getTimezoneDifference(
    serverTimezone = TIMEZONE.EUROPE_WARSAW,
  ): number {
    const userOffset = DateTime.utc().setZone(DateTime.local().zoneName).offset;
    const serverOffset = DateTime.utc().setZone(serverTimezone).offset;

    return (userOffset - serverOffset) / 60;
  }

  function getWarsawTimezoneDifference(timezone: string | null): number {
    const warsawOffset = DateTime.utc().setZone(TIMEZONE.EUROPE_WARSAW).offset;
    let targetOffset;

    switch (timezone) {
      case TIMEZONE.ASIA_MANILA:
        targetOffset = DateTime.utc().setZone(TIMEZONE.ASIA_MANILA).offset;
        break;
      case TIMEZONE.EUROPE_WARSAW:
        targetOffset = DateTime.utc().setZone(TIMEZONE.EUROPE_WARSAW).offset;
        break;
      default:
        targetOffset = DateTime.utc().setZone(checkTimezone()).offset;
    }

    return (targetOffset - warsawOffset) / 60;
  }

  function generateDateFromStringOrDate(date: string | Date): string {
    if (typeof date === 'string') {
      return date;
    }
    return `${date.getFullYear()}-${generateNumber(
      date.getMonth() + 1,
    )}-${generateNumber(
      date.getDate() + 1,
    )}T${date.getHours()}:${date.getMinutes()}:00`;
  }

  function generateTimeWithDifferentTimezone(
    date: string,
    differenceTimezone: number,
  ): string {
    const d = DateTime.fromISO(generateDateFromStringOrDate(date))
      .plus({ hours: differenceTimezone })
      .toISO() as string;
    return d;
  }

  function sortLessonsByDate(
    lessons: CourseInt[],
    sortDesc = false,
  ): CourseInt[] {
    const tmp = lessons.sort(
      (a, b) =>
        new Date(a.start || '').getTime() - new Date(b.start || '').getTime(),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  }

  function sortLessonByDate(
    lessons: LessonInt[],
    sortDesc = false,
  ): LessonInt[] {
    const sortedLessons = lessons.sort(
      (a, b) =>
        new Date(a.startMainTimezone || a.start).getTime() -
        new Date(b.startMainTimezone || b.start).getTime(),
    );

    if (sortDesc) {
      sortedLessons.reverse();
    }

    return sortedLessons;
  }

  function generateTags(url: string, landing: string): Tags {
    const tagSession = sessionStorage.getItem('ga/tags');
    let tags: Tags | null = null;

    const landingUrl = sessionStorage.getItem('ga/landingUrl') || '';
    if (tagSession) {
      const tag: Tags = JSON.parse(tagSession);

      const query: string[] = [];

      Object.entries(tag).forEach(([key, value]) => {
        if (value && value.length) {
          if (typeof value === 'string') {
            query.push(`${key}=${value}`);
          }
          if (typeof value === 'object') {
            query.push(`${key}=${value.join(',')}`);
          }
        }
      });

      tags = {
        ...tag,
        utmUrl:
          landing ||
          landingUrl ||
          `${url}${url.includes('?') ? '&' : '?'}${query.join('&')}`,
        utmLandingUrl: landing || landingUrl,
      };
    } else {
      tags = {
        utmUrl: landing || landingUrl || url,
        utmLandingUrl: landing || landingUrl,
      };
    }
    return tags;
  }

  function generateShortcutStreetType(streetType: string) {
    if (streetType === 'Aleja') {
      return 'al.';
    }
    if (streetType === 'Plac') {
      return 'pl.';
    }
    if (streetType === 'Osiedle') {
      return 'os.';
    }
    return 'ul.';
  }

  function generateSchoolAddress(school: School | null): string {
    let textToDisplay = '';
    if (school) {
      textToDisplay = `${school.name}, ${generateShortcutStreetType(
        school.addressStreetType,
      )} ${school.addressStreetName} ${school.addressHouseNumber}${
        school.addressApartmentNumber
          ? ` /${school.addressApartmentNumber}`
          : ''
      }`;
    }
    return textToDisplay;
  }

  function generateClassroomText(school: School | null): string {
    let textToDisplay = '';
    if (school) {
      textToDisplay = `${generateShortcutStreetType(
        school.addressStreetType,
      )} ${school.addressStreetName} ${school.addressHouseNumber}${
        school.addressApartmentNumber
          ? ` /${school.addressApartmentNumber}`
          : ''
      }, ${school.city}, Sala ${school.classroom}`;
    }
    return textToDisplay;
  }

  function generateFullName(name: string): string {
    const wordsNumber = name.split(' ').length;
    if (wordsNumber > 1) {
      return `${(name || '').split(' ')[0]} ${(name || '').split(' ')[1][0]}.`;
    }
    return name;
  }

  const sortQuizByDate = (lessons: Quiz[], sortDesc = false): Quiz[] => {
    const tmp = lessons.sort(
      (a: Quiz, b: Quiz) =>
        new Date(a.lessonDate).getTime() - new Date(b.lessonDate).getTime(),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  };

  const sortServicesByStudentAmount = (
    services: ServiceStrapi[],
    sortDesc = false,
  ): ServiceStrapi[] => {
    const tmp = services.sort(
      (a: ServiceStrapi, b: ServiceStrapi) =>
        (a?.studentAmount || 0) - (b?.studentAmount || 0),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  };

  const sortLessonsByLessonNumber = (
    lessons: LessonMaterials[],
    sortDesc = false,
  ): LessonMaterials[] => {
    const tmp = lessons.sort(
      (a: LessonMaterials, b: LessonMaterials) =>
        (a?.lessonNumber || 0) - (b?.lessonNumber || 0),
    );
    if (sortDesc) {
      tmp.reverse();
    }
    return tmp;
  };

  const generateLessonsAllowedToShow = (
    lessons: LessonMaterials[],
    courseType: string,
  ): LessonMaterials[] => {
    const today = DateTime.now().toISODate() || '';
    const tmp = lessons.filter(
      (lesson: LessonMaterials) => (lesson?.lessonDate || []) < today,
    );

    if (
      lessons.length > tmp.length &&
      ![CourseType.INDIVIDUAL, CourseType.INDIVIDUAL_NEW].includes(courseType)
    ) {
      const index = lessons.length - tmp.length - 1;
      tmp.unshift(lessons[index]);
    }

    return tmp;
  };

  const generateLessonsAllowedToShowAndOneMore = (
    lessons: LessonMaterials[],
    courseType: string,
  ): LessonMaterials[] => {
    const today = DateTime.now().toISODate() || '';
    const pastLessons = lessons.filter(
      (lesson: LessonMaterials) => (lesson?.lessonDate || []) < today,
    );

    const nextLesson = lessons
      .filter((lesson: LessonMaterials) => (lesson?.lessonDate || []) > today)
      .sort((a, b) => (a.lessonDate > b.lessonDate ? 1 : -1))[0];

    if (
      lessons.length > pastLessons.length &&
      ![CourseType.INDIVIDUAL, CourseType.INDIVIDUAL_NEW].includes(courseType)
    ) {
      const index = lessons.length - pastLessons.length - 1;
      pastLessons.unshift(lessons[index]);
    }

    if (nextLesson) {
      pastLessons.unshift(nextLesson);
    }

    return pastLessons;
  };

  const countingTime = (milliseconds: number): string => {
    let text = '';
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    if (Number(days.toFixed()) > 1) {
      text = `${days.toFixed()} ${context.app.i18n
        .t('COMMON.TIME.DAY.2')
        .toString()} ${hours >= 10 ? hours.toFixed() : `0${hours.toFixed()}`}:${
        minutes >= 10 ? minutes.toFixed() : `0${minutes.toFixed()}`
      }:${seconds >= 10 ? seconds.toFixed() : `0${seconds.toFixed()}`}`;
    } else if (Number(days.toFixed()) === 1) {
      text = ` ${context.app.i18n.t('COMMON.TIME.DAY.1').toString()} ${
        hours >= 10 ? hours.toFixed() : `0${hours.toFixed()}`
      }:${minutes >= 10 ? minutes.toFixed() : `0${minutes.toFixed()}`}:${
        seconds >= 10 ? seconds.toFixed() : `0${seconds.toFixed()}`
      }`;
    } else {
      text = `${hours >= 10 ? hours.toFixed() : `0${hours.toFixed()}`}:${
        minutes >= 10 ? minutes.toFixed() : `0${minutes.toFixed()}`
      }:${seconds >= 10 ? seconds.toFixed() : `0${seconds.toFixed()}`}`;
    }

    return text;
  };

  const isTimerDisplay = (
    timerInformation: TimerInformation | null,
  ): boolean => {
    if (!timerInformation) {
      return false;
    }

    const { isDisplay, endDate, startDisplayDate } = timerInformation;

    if (isDisplay) {
      // if (!endDate && !startDisplayDate) {
      // 	return true
      // }

      const today = DateTime.now().toISODate();
      const endDisplayDate = DateTime.fromISO(endDate || '')
        .minus({ day: 1 })
        .toISODate();
      if (endDate && endDisplayDate && startDisplayDate && today) {
        return endDisplayDate >= today && today >= startDisplayDate;
      }

      if (endDate && endDisplayDate && today) {
        return endDisplayDate >= today;
      }
    }
    return false;
  };

  const shouldDisplayBasedOnDate = (
    startDate: string,
    endDate: string,
  ): boolean => {
    const startDateData = DateTime.fromISO(startDate);
    const endDateData = DateTime.fromISO(endDate);
    const today = DateTime.now();
    const isVisible =
      today.startOf('day') >= startDateData.startOf('day') &&
      today.startOf('day') <= endDateData.startOf('day');
    return isVisible;
  };

  const getWebsiteRoutes = async () => {
    const params = {
      '[filters][pageUrls][id][$ne]': 'null',
      '[populate]': 'pageUrls',
      'pagination[page]': '1',
      'pagination[pageSize]': '100',
      locale: ['en', 'pl'],
    };

    const pages = await context.$httpStrapiSeo.get('/pages', { params });
    const subPages = await context.$httpStrapiSeo.get('/sub-pages', { params });

    const pagesResponse = pages.data.data
      .filter(({ attributes }) => attributes.name)
      .map(({ attributes }) => ({
        name: attributes.name,
        path: `/${attributes.locale}/${attributes.pageUrls.data.attributes.slug}/`,
      }));

    const subPagesResponse = subPages.data.data
      .filter(({ attributes }) => attributes.name)
      .map(({ attributes }) => ({
        name: attributes.name,
        path: `/${attributes.locale}/${attributes.subPageUrl}/${attributes.pageUrls.data.attributes.slug}/`,
      }));

    const routes = [...pagesResponse, ...subPagesResponse];
    const routesObject = routes.reduce((obj, item) => {
      return {
        ...obj,
        [item.name]: item.path,
      };
    }, {});
    return routesObject;
  };

  function parseData(
    data: {
      URL: string;
      Url: string;
      Name: string;
      Description?: string;
      Media: Media | null;
    }[],
  ) {
    const ret = [] as { id: number; link?: string; text: string }[];
    if (data) {
      if (typeof data === 'string') {
        ret.push({
          id: 0,
          link: '',
          text: data,
        });
      } else {
        if (hasOwnProperty(data, 'data')) {
          // @ts-ignore
          data = data.data;
        }
        (data || []).forEach((h) => {
          ret.push({
            id: parseInt(h.id), // Nowe pole id
            link: h.Url || h.URL || h.Media?.data?.attributes?.url,
            text: h.Description || h.Name,
          });
        });
      }
    }
    return ret;
  }

  function getHourFromDate(dateString: string): string {
    const dateTime = DateTime.fromISO(dateString);
    const hours = dateTime.toFormat('HH');
    const minutes = dateTime.toFormat('mm');
    return `${hours}:${minutes}`;
  }

  function getDayAndHourFromDate(dateString: string): string {
    const date = new Date(dateString);
    const dayKey = 'COMMON.DAYS2.' + date.getDay().toString();
    const day = context.app.i18n.t(dayKey);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day} ${hours}:${minutes}`;
  }

  const getTimeDifferenceMilliseconds = (
    startHour: string,
    endHour: string,
  ): number => {
    const endDate = new Date('1970-01-01 ' + endHour);
    const startDate = new Date('1970-01-01 ' + startHour);
    return endDate.getTime() - startDate.getTime();
  };

  const convertPriceFromNumberToString = (price: number | string): string => {
    if (price || price === 0) {
      return price.toString().replace('.', ',');
    }

    return '';
  };

  const generateCorrectMonthText = (duration: number): string => {
    if (duration === 1) {
      return `${duration} ${context.app.i18n.t('COMMON.TIME.MONTH.1')}`;
    }

    if (duration >= 2 && duration <= 4) {
      return `${duration} ${context.app.i18n.t('COMMON.TIME.MONTH.2')}`;
    }

    return `${duration} ${context.app.i18n.t('COMMON.TIME.MONTH.3')}`;
  };

  const generateCorrectLessonsPerWeekText = (
    selectedPackage: number,
    numberOfLessons?: number,
  ): string => {
    let lessonText = '';
    if (selectedPackage === 1) {
      lessonText = context.app.i18n.t(
        'ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.1',
      );
    } else if (selectedPackage >= 2 && selectedPackage <= 4) {
      lessonText = context.app.i18n.t(
        'ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.2',
      );
    } else {
      lessonText = context.app.i18n.t(
        'ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.3',
      );
    }

    if (numberOfLessons) {
      return `${numberOfLessons} ${lessonText}`;
    }

    return context.app.i18n.t('ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.3', {
      selectedPackage,
      lessonText,
    });
  };

  const generateIndividualStepType = (
    account: Account | null | undefined,
    pollRequired: boolean,
  ): StepDescriptionType => {
    if (account && pollRequired) {
      return StepDescriptionStatus.LOGGED_POLL_REQUIRED;
    }
    if (account && !pollRequired) {
      return StepDescriptionStatus.LOGGED_POLL_NOT_REQUIRED;
    }
    if (!account && pollRequired) {
      return StepDescriptionStatus.NOT_LOGGED_POLL_REQUIRED;
    }
    return StepDescriptionStatus.NOT_LOGGED_POLL_NOT_REQUIRED;
  };

  const FormatTimeToAmPm = (
    date: Date | string,
    is12HourFormat: boolean,
  ): string => {
    const normalizedDate = new Date(date);
    if (is12HourFormat) {
      return DateTime.fromJSDate(normalizedDate).toFormat('h:mm a');
    } else {
      return DateTime.fromJSDate(normalizedDate).toFormat('HH:mm');
    }
  };

  const generateMaterialServiceGradeTextHelper = (
    minGrade: number,
    maxGrade: number,
  ): string => {
    if (minGrade === 99 || maxGrade === 99) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.ADULTS',
        )
        .toString();
    }
    const getMaterialServiceGradeText = (grade: number) =>
      context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASS',
          {
            grade,
          },
        )
        .toString();
    if (minGrade === maxGrade) {
      return getMaterialServiceGradeText(minGrade);
    }
    if (minGrade && maxGrade) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASSES',
          {
            minGrade,
            maxGrade,
          },
        )
        .toString();
    }
    if (minGrade || maxGrade) {
      return getMaterialServiceGradeText(minGrade || maxGrade);
    }

    return context.app.i18n
      .t(
        'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.NO_DATA',
      )
      .toString();
  };

  const generateStudentGradeTextHelper = (grade: number): string => {
    if (grade === 99) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.ADULT',
        )
        .toString();
    }

    if (grade >= 1 && grade <= 8) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.PRIMARY_STUDENT',
          {
            grade,
          },
        )
        .toString();
    }

    if (grade >= 9 && grade <= 13) {
      const secondarySchoolGrade = grade - 8;
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.SECONDARY_STUDENT',
          {
            secondarySchoolGrade,
          },
        )
        .toString();
    }

    return context.app.i18n
      .t(
        'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.NO_DATA',
      )
      .toString();
  };

  const generatePolishDisplayTime = (
    time: string,
    differenceTimezone: number,
  ): string => {
    const timeWithMilliseconds = `${time}:00`;
    const dateTimeString = generateTimeWithDifferentTimezone(
      timeWithMilliseconds,
      -differenceTimezone,
    );
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const calculatedTime = `${hours}:${minutes}`;
    return calculatedTime;
  };

  const generateAvailabilityWithTimezoneDifferences = (
    availability: Availability[],
    difference: number,
  ): Availability[] => {
    const availabilityTmp: Availability[] = [];

    if (difference === 0) {
      return availability;
    }

    availability.forEach((availabilityElement: Availability) => {
      const startTimeSplit = availabilityElement.startTime.split(':');
      const startTime = Number(startTimeSplit[0]);
      const endTimeSplit = availabilityElement.endTime.split(':');
      const endTime = Number(endTimeSplit[0]);

      const generateDayNumber = (dayNumber: number): number => {
        if (dayNumber > 6) {
          return 0;
        }

        if (dayNumber < 0) {
          return 6;
        }

        return dayNumber;
      };

      const generateTime = (time: number): number => {
        if (time > 24) {
          return time - 24;
        } else if (time < 0) {
          return time + 24;
        }

        return time;
      };

      const generateHour = (hour: string): string => {
        const timeSplit = hour.split(':');
        let time = generateTime(Number(timeSplit[0]));

        if (time > 24) {
          time = time - 24;
        } else if (time < 0) {
          time = time + 24;
        }

        return `${generateNumber(time)}:${timeSplit[1]}`;
      };

      const startTimeWithDifference = `${generateNumber(
        generateTime(startTime + difference),
      ).toString()}:${startTimeSplit[1]}`;
      const endTimeWithDifference = `${generateNumber(
        generateTime(endTime + difference),
      ).toString()}:${endTimeSplit[1]}`;

      const a = {
        id: availabilityElement.id,
        dayOfWeekNumber: availabilityElement.dayOfWeekNumber,
        startTime: generateHour(
          `${startTimeWithDifference.split(':')[0]}:${startTimeSplit[1]}`,
        ),
        endTime: generateHour(
          `${endTimeWithDifference.split(':')[0]}:${endTimeSplit[1]}`,
        ),
      };

      if (
        availabilityElement.startTime < a.startTime &&
        availabilityElement.endTime > a.endTime &&
        endTimeWithDifference >= '24:00'
      ) {
        availabilityTmp.push(
          {
            id: availabilityElement.id,
            dayOfWeekNumber: availabilityElement.dayOfWeekNumber,
            startTime: generateHour(a.startTime),
            endTime: '24:00',
          },
          {
            id: availabilityElement.id,
            dayOfWeekNumber: generateDayNumber(a.dayOfWeekNumber + 1),
            startTime: '00:00',
            endTime: generateHour(a.endTime),
          },
        );
      } else if (
        availabilityElement.startTime < a.startTime &&
        availabilityElement.endTime > a.endTime &&
        startTimeWithDifference <= '00:00'
      ) {
        availabilityTmp.push(
          {
            id: availabilityElement.id,
            dayOfWeekNumber: generateDayNumber(a.dayOfWeekNumber - 1),
            startTime: generateHour(a.startTime),
            endTime: '24:00',
          },
          {
            id: availabilityElement.id,
            dayOfWeekNumber: availabilityElement.dayOfWeekNumber,
            startTime: '00:00',
            endTime: generateHour(a.endTime),
          },
        );
      } else if (
        availabilityElement.startTime > a.startTime &&
        availabilityElement.endTime > a.endTime &&
        endTimeWithDifference >= '24:00'
      ) {
        availabilityTmp.push({
          id: availabilityElement.id,
          dayOfWeekNumber: generateDayNumber(a.dayOfWeekNumber + 1),
          startTime: generateHour(a.startTime),
          endTime: generateHour(a.endTime),
        });
      } else if (
        ((availabilityElement.startTime > a.startTime &&
          availabilityElement.endTime > a.endTime) ||
          (availabilityElement.startTime < a.startTime &&
            availabilityElement.endTime < a.endTime)) &&
        endTimeWithDifference <= '24:00' &&
        startTimeWithDifference >= '00:00'
      ) {
        availabilityTmp.push({
          id: availabilityElement.id,
          dayOfWeekNumber: a.dayOfWeekNumber,
          startTime: generateHour(a.startTime),
          endTime: generateHour(a.endTime),
        });
      } else if (
        availabilityElement.startTime < a.startTime &&
        availabilityElement.endTime < a.endTime &&
        startTimeWithDifference <= '00:00'
      ) {
        availabilityTmp.push({
          id: availabilityElement.id,
          dayOfWeekNumber: generateDayNumber(a.dayOfWeekNumber - 1),
          startTime: generateHour(a.startTime),
          endTime: generateHour(a.endTime),
        });
      }
    });

    const sortedAvailabilityTmp = availabilityTmp.sort(
      (a: Availability, b: Availability) => {
        const aStartTimeSplitted = a.startTime.split(':');
        const bStartTimeSplitted = b.startTime.split(':');
        return (
          a.dayOfWeekNumber - b.dayOfWeekNumber ||
          Number(aStartTimeSplitted[0]) - Number(bStartTimeSplitted[0]) ||
          Number(aStartTimeSplitted[1]) - Number(bStartTimeSplitted[1])
        );
      },
    );
    return sortedAvailabilityTmp;
  };

  const generatePolishDisplayFullTime = (
    dayOfWeekNumber: number,
    startHour: string,
    endHour: string,
    differenceTimezone: number,
  ): string => {
    const timeStart = generatePolishDisplayTime(startHour, differenceTimezone);
    const timeEnd = generatePolishDisplayTime(endHour, differenceTimezone);

    if (startHour < timeStart && timeStart < timeEnd) {
      return `${context.app.i18n.t(
        `COMMON.DAYS2.${dayOfWeekNumber}`,
      )}, ${timeStart} - ${timeEnd}`;
    }

    if (startHour > timeStart && timeStart < timeEnd) {
      return `${context.app.i18n.t(
        `COMMON.DAYS2.${dayOfWeekNumber + 1}`,
      )}, ${timeStart} - ${timeEnd}`;
    }

    return `${context.app.i18n.t(
      `COMMON.DAYS2.${dayOfWeekNumber}`,
    )}, ${timeStart} - ${context.app.i18n.t(
      `COMMON.DAYS2.${dayOfWeekNumber + 1}`,
    )}, ${timeEnd}`;
  };
  const getLessonTypeText = (
    lessonCount: number,
    totalLessons: number,
  ): string => {
    const lessonTypeTranslations = context.app.i18n.t(
      'ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE',
    );
    const separatorZ = context.app.i18n
      .t(
        'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.SEPARATOR_Z',
      )
      .toString();
    const lessonTypeKey =
      lessonCount === 1 ? 1 : lessonCount > 1 && lessonCount < 5 ? 2 : 3;
    const lessonType = lessonTypeTranslations[lessonTypeKey];

    return `<b>${lessonCount} ${lessonType}</b> ${separatorZ} ${totalLessons}`;
  };

  const generateLessonsWithCorrectTimezone = (
    lessons: LessonInt[],
  ): LessonInt[] => {
    if (!lessons) {
      return [];
    }

    const differenceTimezone = getWarsawTimezoneDifference(null);

    return lessons.map((lesson: LessonInt) => ({
      ...lesson,
      start: generateTimeWithDifferentTimezone(
        generateDateFromStringOrDate(lesson.start || ''),
        differenceTimezone,
      ),
      end: generateTimeWithDifferentTimezone(
        generateDateFromStringOrDate(lesson.end || ''),
        differenceTimezone,
      ),
    }));
  };

  const generateContinuesStudentGrade = (
    minGrade: number,
    maxGrade: number,
  ): string => {
    if (minGrade === 99 || maxGrade === 99) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.ADULTS',
        )
        .toString();
    }

    const getMaterialServiceGradeText = (grade: number) => {
      if (grade >= 1 && grade <= 8) {
        return context.app.i18n
          .t(
            'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASS_PRIMARY',
            {
              grade,
            },
          )
          .toString();
      } else if (grade >= 9 && grade <= 13) {
        const adjustedGrade = grade - 8;
        return context.app.i18n
          .t(
            'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASS_SECONDARY',
            {
              grade: adjustedGrade,
            },
          )
          .toString();
      }
    };

    if (minGrade === maxGrade) {
      return getMaterialServiceGradeText(minGrade);
    }

    if (minGrade >= 9 && maxGrade >= 9) {
      const minAdjustedGrade = minGrade - 8;
      const maxAdjustedGrade = maxGrade - 8;
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASSES_SECONDARY',
          {
            minGrade: minAdjustedGrade,
            maxGrade: maxAdjustedGrade,
          },
        )
        .toString();
    } else if (minGrade <= 8 && maxGrade <= 8) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.CLASSES_PRIMARY',
          {
            minGrade,
            maxGrade,
          },
        )
        .toString();
    }

    if (minGrade || maxGrade) {
      return getMaterialServiceGradeText(minGrade || maxGrade);
    }

    return context.app.i18n
      .t(
        'PAGES.DASHBOARD.TEACHER.REVAMP.INDIVIDUAL_LESSONS.INDIVIDUAL_DETAILS.HELPER.NO_DATA',
      )
      .toString();
  };

  function parseLinkContent(content: string): Subpart[] {
    const boldStrongRegex = /(\*\*|<strong>)(.*?)(\*\*|<\/strong>)/g;
    const linkParts: Subpart[] = [];
    let lastLinkIndex = 0;
    let boldStrongMatch;

    while ((boldStrongMatch = boldStrongRegex.exec(content)) !== null) {
      if (boldStrongMatch.index > lastLinkIndex) {
        linkParts.push({
          content: content.slice(lastLinkIndex, boldStrongMatch.index),
          type: PartType.Text,
        });
      }
      linkParts.push({ content: boldStrongMatch[2], type: PartType.Bold });
      lastLinkIndex = boldStrongRegex.lastIndex;
    }

    if (lastLinkIndex < content.length) {
      linkParts.push({
        content: content.slice(lastLinkIndex),
        type: PartType.Text,
      });
    }

    return linkParts;
  }

  function parseHtmlText(text: string): (string | Subpart)[] {
    if (!text) {
      return [{ content: '', type: PartType.Text }];
    }
    const regex =
      /(\*\*(.*?)\*\*|<a href="(.*?)">(.*?)<\/a>|<strong>(.*?)<\/strong>|<br ?\/?>)/g;
    const parts: (string | Subpart)[] = [];
    let lastIndex = 0;
    let match;

    const cleanedText = text.replace(/<\/?p>/g, '');

    while ((match = regex.exec(cleanedText)) !== null) {
      if (match.index > lastIndex) {
        parts.push({
          content: cleanedText.slice(lastIndex, match.index),
          type: PartType.Text,
        });
      }
      if (match[1].startsWith('**')) {
        parts.push({ content: match[2], type: PartType.Bold });
      } else if (match[1].startsWith('<a')) {
        const linkContent = parseLinkContent(match[4]);
        parts.push({
          content: linkContent,
          href: match[3],
          type: PartType.Link,
        });
      } else if (match[1].startsWith('<strong>')) {
        parts.push({ content: match[5], type: PartType.Bold });
      } else if (match[1].startsWith('<br')) {
        parts.push({ type: PartType.Br, content: '' });
      }
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < cleanedText.length) {
      parts.push({
        content: cleanedText.slice(lastIndex),
        type: PartType.Text,
      });
    }

    return parts;
  }

  function formatDateString(dateString: string, locale = 'pl'): string {
    if (!dateString) return '';

    const date = DateTime.fromISO(dateString).setLocale(locale);
    const splittedDate = date.toFormat('dd LLL yyyy ccc').split(' ');
    const day = splittedDate[0];
    const month = splittedDate[1];
    const year = splittedDate[2];
    const dayName = splittedDate[3];

    return `<b>${day} ${capitalize(month)} ${year}</b> (${capitalize(
      dayName,
    )})`;
  }

  function formatDateRange(
    start: string,
    end: string,
    allDayStart = false,
    allDayEnd = false,
    locale = 'pl',
  ): string {
    if (!start && !end) {
      return '';
    }

    let formattedStartDate = '';
    let formattedEndDate = '';
    let formattedStartTime = '';
    let formattedEndTime = '';

    if (start) {
      const startDateTime = DateTime.fromISO(start).setLocale(locale);
      const startDateFormatted = startDateTime.toFormat('dd LLL yyyy ccc');
      const [day, month, year, dayOfWeek] = startDateFormatted.split(' ');

      formattedStartDate = `<b>${day} ${capitalize(
        month,
      )} ${year}</b> (${capitalize(dayOfWeek)})`;
      formattedStartTime = startDateTime.toFormat('HH:mm');
    }

    if (end) {
      const endDateTime = DateTime.fromISO(end).setLocale(locale);
      const endDateFormatted = endDateTime.toFormat('dd LLL yyyy ccc');
      const [endDay, endMonth, endYear, endDayOfWeek] =
        endDateFormatted.split(' ');

      formattedEndDate = `<b>${endDay} ${capitalize(
        endMonth,
      )} ${endYear}</b> (${capitalize(endDayOfWeek)})`;
      formattedEndTime = endDateTime.toFormat('HH:mm');
    }

    if (allDayStart && !allDayEnd) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.TEACHER_ABSENCE.DATE_RANGE.DATE_END',
          {
            start: formattedStartDate,
            end: formattedEndDate,
            endTime: formattedEndTime,
          },
        )
        .toString();
    }

    if (!allDayStart && allDayEnd) {
      return context.app.i18n
        .t(
          'PAGES.DASHBOARD.TEACHER.REVAMP.TEACHER_ABSENCE.DATE_RANGE.DATE_START',
          {
            start: formattedStartDate,
            startTime: formattedStartTime,
            end: formattedEndDate,
          },
        )
        .toString();
    }

    if (allDayStart && allDayEnd) {
      return context.app.i18n
        .t('PAGES.DASHBOARD.TEACHER.REVAMP.TEACHER_ABSENCE.DATE_RANGE.DATE', {
          start: formattedStartDate,
          end: formattedEndDate,
        })
        .toString();
    }

    return context.app.i18n
      .t('PAGES.DASHBOARD.TEACHER.REVAMP.TEACHER_ABSENCE.DATE_RANGE.HOURS', {
        start: formattedStartDate,
        end: formattedEndDate,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      })
      .toString();
  }

  function formatSingleDateTimeObject(dateObject: LessonData, locale = 'pl') {
    const dateTime = DateTime.fromISO(dateObject.date).setLocale(locale);
    const formattedDate = dateTime.toFormat('dd LLL yyyy ccc');

    const parts = formattedDate.split(' ');
    const day = parts[0];
    const month = capitalize(parts[1]);
    const year = parts[2];
    const dayOfWeek = capitalize(parts[3]);

    const date = `${day} ${month} ${year} (${dayOfWeek})`;
    const startTime = DateTime.fromISO(dateObject.startTime).toFormat('HH:mm');
    const endTime = DateTime.fromISO(dateObject.endTime).toFormat('HH:mm');

    return `${date}, ${startTime}-${endTime}`;
  }

  const computedBackgroundStyle = (
    type: string,
    courseId: number,
    icon?: string,
  ) => {
    const iconToDisplay = getIndividualIcon(type, courseId, icon);
    return `background-image:url(${iconToDisplay})`;
  };

  const getIndividualIcon = (
    type: string,
    courseId: number,
    icon?: string,
  ): string => {
    const defaultIcon = require('@/assets/img/course-image.svg');
    const backgroundImageUrl = [
      CourseType.INDIVIDUAL,
      CourseType.INDIVIDUAL_NEW,
    ].includes(type);

    if (backgroundImageUrl) {
      const mockData = courseCardsMock.find(
        (mock) => mock.courseId === courseId,
      );
      return mockData?.iconUrl || defaultIcon;
    }

    return icon || defaultIcon;
  };

  const getWebsiteLocaleSlugs = (pageName: string): string => {
    const locale: string = context.i18n.locale ?? 'pl';
    const baseUrl: string = `${context.$config.websiteUrl}/${locale}`;
    const slug: WebsiteSlugInt = WebsiteSlugs[pageName];

    if (!slug?.[locale as keyof WebsiteSlugInt]) {
      return `${baseUrl}`;
    } else {
      return `${baseUrl}` + slug[locale as keyof WebsiteSlugInt];
    }
  };

  const generateDate = (
    time: number | string,
    format = DATE_FORMAT.DD_MM_YYYY,
  ): string => {
    if (!time || time === '-') {
      return '-';
    }

    const date =
      typeof time === 'number'
        ? DateTime.fromMillis(time)
        : DateTime.fromISO(time);

    const year = date.year.toString();
    const month = date.month.toString();
    const day = date.day.toString();
    const dayWeek = date.weekday;
    const hour = date.hour.toString();
    const minute = date.minute.toString();

    let output = '-';

    if (format === DATE_FORMAT.DD_MM_YYYY) {
      output = `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
    }

    if (format === DATE_FORMAT.DD_MMM_YYYY_HH_MM) {
      output = `${day.padStart(2, '0')} ${context.app.i18n
        .t(`COMMON.MONTHS_SHORT.${date.month.toString()}`)
        .toString()} (${hour.padStart(2, '0')}:${minute.padStart(
        2,
        '0',
      )})${year}`;
    }

    if (format === DATE_FORMAT.DD_MMM_DW_YYYY) {
      output = `${day.padStart(2, '0')} ${context.app.i18n
        .t(`COMMON.MONTHS_SHORT.${date.month.toString()}`)
        .toString()} (${context.app.i18n
        .t(`COMMON.DAYS.${dayWeek}`)
        .toString()
        .toLowerCase()}) ${year}`;
    }

    if (format === DATE_FORMAT.DD_MMM_YYYY) {
      output = `${day.padStart(2, '0')} ${context.app.i18n
        .t(`COMMON.MONTHS_SHORT.${date.month.toString()}`)
        .toString()} ${year}`;
    }

    return output;
  };

  const generateGradeText = (grade: string | number): string => {
    const gradeNumber = Number(grade);
    if (gradeNumber <= 8) {
      return `${grade} ${context.app.i18n
        .t('COMMON.PRIMARY_GRADE')
        .toString()}`;
    }

    if (gradeNumber <= 13) {
      return `${gradeNumber - 8}  ${context.app.i18n
        .t('COMMON.PRIMARY_GRADE')
        .toString()}`;
    }

    return context.app.i18n.t('COMMON.ADULT').toString();
  };

  const getFileType = (filePath: string): string => {
    if (!filePath) {
      return '';
    }
    const filePathArray = filePath.split('.');
    const fileType = filePathArray[filePathArray.length - 1];
    return fileType;
  };

  const parseMaterials = (
    data: {
      URL: string;
      Url: string;
      Name: string;
      Description?: string;
      Comment?: string;
      Media: Media | null;
      type?: string;
    }[],
  ) => {
    const ret = [] as { link?: string; text: string; type?: string }[];
    if (data) {
      if (typeof data === 'string') {
        ret.push({
          link: '',
          text: data,
          type: 'text',
        });
      } else {
        // eslint-disable-next-line no-prototype-builtins
        if (hasOwnProperty(data, 'data')) {
          // @ts-ignore
          data = data.data;
        }
        (data || []).forEach((h) => {
          ret.push({
            link: h.Url || h.URL || h.Media?.data?.attributes?.url,
            text:
              h.Description ||
              h.Name ||
              h.Comment ||
              h.Url ||
              h.URL ||
              h.Media?.data?.attributes?.url ||
              '',
            type: h.type || '',
          });
        });
      }
    }
    return ret;
  };

  const getLessonFormFromTranslations = (number: number) => {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
    let form = '';

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      form = context.app.i18n.t('ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.3');
    } else if (lastDigit === 1 && lastTwoDigits !== 11) {
      form = context.app.i18n.t('ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.1');
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      form = context.app.i18n.t('ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.2');
    } else {
      form = context.app.i18n.t('ONE_BY_ONE.PACKAGE_INFORMATION.LESSON_TYPE.3');
    }

    return `${number} ${form}`;
  };

  const helpers = {
    getWebsiteLocaleSlugs,
    Variants,
    TimeSince,
    LeadingZero,
    DateOperations,
    ParseDateToTimeSince,
    ParseDate,
    GetDate,
    GetTime,
    GenerateLessonDate,
    GetMonday,
    formatLessonTime,
    sortByDate,
    getTodayLessons,
    getNextLesson,
    getNextLessonNotStarted,
    getPrevLesson,
    getLessonLastWeek,
    getLessonThisWeek,
    getLessonNextWeek,
    DeepCopy,
    TunnelEmitter,
    getSideBarItems,
    getBaseUrl,
    capitalize,
    checkIfEnterToLessonEnabled,
    checkRateLessonEnabled,
    calculateLessonRate,
    primarySchoolGrade,
    highSchoolGrade,
    parseCookie,
    setCookie,
    formatPrice,
    allowBoldTag,
    toBold,
    toNewLine,
    timeGrid,
    generateFileType,
    videoParser,
    generateSpecializationType,
    generateFormattedDate,
    sortNotificationsByDate,
    generateNumber,
    convertPriceFromNumberToString,
    countryCodesForOnboarding,
    generateKeyByValue,
    hasOwnProperty,
    checkTimezone,
    getWarsawTimezoneDifference,
    generateDateFromStringOrDate,
    generateTimeWithDifferentTimezone,
    sortLessonsByDate,
    sortLessonByDate,
    generateTags,
    generateSchoolAddress,
    generateClassroomText,
    sortQuizByDate,
    sortServicesByStudentAmount,
    sortLessonsByLessonNumber,
    generateLessonsAllowedToShow,
    generateFullName,
    isTimerDisplay,
    countingTime,
    shouldDisplayBasedOnDate,
    getWebsiteRoutes,
    parseData,
    getHourFromDate,
    getDayAndHourFromDate,
    getTimeDifferenceMilliseconds,
    generateCorrectMonthText,
    generateCorrectLessonsPerWeekText,
    generateIndividualStepType,
    FormatTimeToAmPm,
    generateMaterialServiceGradeTextHelper,
    generateStudentGradeTextHelper,
    generatePolishDisplayTime,
    generatePolishDisplayFullTime,
    computedBackgroundStyle,
    getIndividualIcon,
    getLessonTypeText,
    generateLessonsWithCorrectTimezone,
    generateAvailabilityWithTimezoneDifferences,
    getTimezoneDifference,
    generateContinuesStudentGrade,
    parseHtmlText,
    formatDateString,
    formatDateRange,
    formatSingleDateTimeObject,
    generateDate,
    generateGradeText,
    parseMaterials,
    getFileType,
    generateLessonsAllowedToShowAndOneMore,
    getLessonFormFromTranslations,
  };

  inject('helpers', helpers);
  return helpers;
};

declare module 'vue/types/vue' {
  interface Vue {
    $helpers: ReturnType<typeof helpers>;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $helpers: ReturnType<typeof helpers>;
  }
  interface Context {
    $helpers: ReturnType<typeof helpers>;
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  interface Store<S> {
    $helpers: ReturnType<typeof helpers>;
  }
}

export default helpers;
