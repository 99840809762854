import { MutationTree } from 'vuex/types/index';
import { set } from '../../utils/vuex';
import { CommonState } from './stateType';

const mutations: MutationTree<CommonState> = {
  setRedirectTo: set('redirectTo'),
  setRedirectFromEnroll: set('redirectFromEnroll'),
  setRedirectFromCompleteData: set('redirectFromCompleteData'),
  setEnrollDate: set('enrollDate'),
  setChoosenServices: set('choosenServices'),
  setShowNotLoginDialog: set('showNotLoginDialog'),
  setCourseInformation: set('courseInformation'),
  setEnrollCourseInformation: set('enrollCourseInformation'),
  setToken: set('token'),
  setChoosenCourses: set('choosenCourses'),
  setShowEnrollTimer: set('showEnrollTimer'),
  setEnrollTimerStatus: set('enrollTimerStatus'),
  setIsAlert: set('isAlert'),
  setIsValidated: set('isValidated'),
  setCounter: set('counter'),
  setReUseLessons: set('reUseLessons'),
  setLocale: set('locale'),
  setShowTeacherDayBaner: set('showTeacherDayBaner'),
  setEnrollRulesData: set('enrollRulesData'),
  setPaymentPromoCode: set('paymentPromoCode'),
  setPriceInformation: set('priceInformation'),
  setWebsiteRoutes: set('websiteRoutes'),
  setProductValuesPages: set('productValuesPages'),
  setReservationIndividualData: set('reservationIndividualData'),
};

export default mutations;
