var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dialog-card',[_c('v-card',{staticStyle:{"position":"relative","padding":"24px","display":"flex","flex-direction":"column"}},[_c('v-icon',{staticStyle:{"color":"#000","position":"absolute","right":"16px","top":"16px"},attrs:{"data-button":"home-gototeacher-popup-btn-close"},on:{"click":function($event){return _vm.$emit('setShowDialog', false)}}},[_vm._v("\n      "+_vm._s(_vm.mdiClose)+"\n    ")]),_vm._v(" "),_c('span',{staticStyle:{"max-width":"386px","color":"#1d1d4e","font-size":"20px","font-weight":"600"}},[_vm._v(_vm._s(_vm.$t('TEACHER_REGISTER.LOGOUT_DIALOG.TITLE')))]),_vm._v(" "),_c('span',{staticStyle:{"color":"#1d1d4e","font-size":"16px","margin-top":"16px","margin-bottom":"32px"}},[_vm._v("\n      "+_vm._s(_vm.$t('TEACHER_REGISTER.LOGOUT_DIALOG.DESCRIPTION'))+"\n    ")]),_vm._v(" "),_c('v-col',{staticClass:"ma-0 pa-0",style:(`display: flex; flex-direction: ${
        _vm.$vuetify.breakpoint.mdAndUp ? 'row' : 'column'
      }; justify-content: left; align-items: center;`),attrs:{"cols":"12"}},[_c('t-btn',{style:(`background-color: #823189;  font-size: 14px; margin-right: 8px; width: ${
          _vm.$vuetify.breakpoint.smAndDown ? '94%' : ''
        };`),attrs:{"color":"white","text":"","data-button":"home-gototeacher-popup-btn-logout"},on:{"click":function($event){return _vm.$emit('setShowDialog', true)}}},[_vm._v("\n        "+_vm._s(_vm.$t('TEACHER_REGISTER.LOGOUT_DIALOG.LOGOUT'))+"\n      ")]),_vm._v(" "),_c('t-btn',{class:{
          'ml-5': _vm.$vuetify.breakpoint.mdAndUp,
          'mt-5': !_vm.$vuetify.breakpoint.mdAndUp,
        },style:(`background-color: #F8F3F8; font-size: 14px; width: ${
          _vm.$vuetify.breakpoint.smAndDown ? '94%' : ''
        };`),attrs:{"color":"#823189","text":"","data-button":"home-gototeacher-popup-btn-cancel"},on:{"click":function($event){return _vm.$emit('setShowDialog', false)}}},[_vm._v("\n        "+_vm._s(_vm.$t('TEACHER_REGISTER.LOGOUT_DIALOG.CANCEL'))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }