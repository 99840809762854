import { CommonState } from './stateType';

export default (): CommonState => ({
  redirectTo: '/',
  redirectFromEnroll: '/',
  redirectFromCompleteData: '/',
  enrollDate: {},
  choosenServices: [],
  showNotLoginDialog: false,
  courseInformation: {},
  enrollCourseInformation: {},
  priceInformation: {},
  token: '',
  choosenCourses: [],
  showEnrollTimer: false,
  enrollTimerStatus: '',
  isAlert: false,
  isValidated: false,
  counter: 0,
  reUseLessons: [],
  locale: '',
  showTeacherDayBaner: false,
  enrollRulesData: {
    firstCheckbox: false,
    secondCheckbox: false,
    thirdCheckbox: false,
    fourthCheckbox: false,
    fifthCheckbox: false,
  },
  paymentPromoCode: {
    promoCode: '',
    isInputDisabled: false,
    isPromoCodeValid: false,
  },
  websiteRoutes: {},
  productValuesPages: {},
  reservationIndividualData: {
    studentProfileId: 0,
    studentName: '',
    courseId: 0,
    courseName: '',
    startDate: '',
    startTime: '',
    clientEmail: '',
    type: '',
  },
});
