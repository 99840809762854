import { Plugin } from '@nuxt/types';
import { AxiosStrapiType } from '../types/strapi/axiosStrapi';

const httpStrapi: Plugin = (
  { $axios, i18n, $config: { cmsBaseUrl } }: any,
  inject,
) => {
  const baseURL = cmsBaseUrl;

  const axiosInstance = $axios.create({
    baseURL,
    timeout: 30000,
  });

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      if (process.server) {
        console.log(
          `[httpStrapi] ${config.method?.toUpperCase()} ${config.baseURL}${
            config.url
          }`,
        );
      }
      const returnConfig = await config;
      delete returnConfig.headers.common.authorization;
      if (process.client) {
        if (await returnConfig.params) {
          if (i18n?.locale) {
            returnConfig.params['Accept-Language'] = i18n.locale;
          } else {
            returnConfig.params['Accept-Language'] = 'pl';
          }
        } else {
          returnConfig.params['Accept-Language'] = 'pl';
        }
      }
      return returnConfig;
    },
    (error: any) => Promise.reject(error),
  );

  inject('httpStrapi', axiosInstance);
};

declare module 'vue/types/vue' {
  interface Vue {
    $httpStrapi: AxiosStrapiType;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $httpStrapi: AxiosStrapiType;
  }
  interface Context {
    $httpStrapi: AxiosStrapiType;
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $httpStrapi: AxiosStrapiType;
  }
}

export default httpStrapi;
