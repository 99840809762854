
import { defineComponent } from 'vue';
import { BreadcrumbInt } from '../../types/interfaces';
import slugs from '~/locales/slugs';

export default defineComponent({
  name: 'BreadCrumb',
  props: {
    path: {
      type: Array as () => BreadcrumbInt[][],
      required: true,
      default: (): BreadcrumbInt[][] => [],
    },
    current: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      breadCrumb: [] as BreadcrumbInt[],
      slug: slugs as any,
    };
  },
  watch: {
    path: {
      immediate: true,
      handler() {
        this.getItems();
      },
    },
  },
  methods: {
    getItems(): void {
      const tmp: BreadcrumbInt[][] = this.path;
      this.breadCrumb = tmp.flat();
    },
  },
});
