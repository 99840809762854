import { Mutation } from 'vuex';

export function set<State>(property: keyof State): Mutation<State> {
  return (state: State, payload) => {
    state[property] = payload;
  };
}

export function toggle<State>(property: keyof State): Mutation<State> {
  return (state: State) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state[property] = !state[property];
  };
}
