
import { defineComponent } from 'vue';
import { TBtn } from '../../node_modules/@musicmore/tutore-components/';
import CookieDialog from './CookieDialog.vue';
import cookiesArray from './cookies';
import {
  Window,
  ConsentsValues,
  ConsentsInt,
  CookiesText,
} from '@/types/interfaces';
import { Consents } from '@/types/enums/Consents';
import { ConsentsEvent } from '@/types/enums/ConsentsEvent';

interface GtmInjected {
  init(id: string): void;
  push(obj: IArguments): void;
}

interface NuxtFacebookPixel {
  enable(): void;
  disable(): void;
  init(): void;
}

declare const window: Window & typeof globalThis;

declare module 'vue/types/vue' {
  interface Vue {
    readonly $gtm: GtmInjected;
    readonly $fb: NuxtFacebookPixel;
  }
}

const CookieVisibleArray = ['SchoolsMap'];

export default defineComponent({
  name: 'CookieBanner',
  components: {
    CookieDialog,
    TBtn,
  },
  data() {
    return {
      show: true,
      showDialog: false,
      showError: false,
      consents: {} as ConsentsValues,
    };
  },
  computed: {
    cookieVisible(): boolean {
      return !CookieVisibleArray.includes(
        this.$route.name?.replace('___pl', '').replace('___en', '') ?? '',
      );
    },
  },
  mounted() {
    const status = localStorage.getItem('consents');
    const cookieConset = this.$cookies.get('consents');

    this.gtag(ConsentsEvent.CONSENT, ConsentsEvent.DEFAULT, {
      functionality_storage: Consents.DENIED,
      ad_storage: Consents.DENIED,
      ad_user_data: Consents.DENIED,
      ad_personalization: Consents.DENIED,
      analytics_storage: Consents.DENIED,
      personalization_storage: Consents.DENIED,
      security_storage: Consents.GRANTED,
      wait_for_update: 2500,
    });

    try {
      if (!status && !cookieConset) {
        cookiesArray.forEach((consent: CookiesText) => {
          this.consents[consent.id] = consent.value;
        });
      } else {
        this.consents = JSON.parse(status || cookieConset);
        this.updateGoogleConsents(this.consents);
      }

      this.show = !status && !cookieConset;
    } catch {
      if (cookieConset) {
        this.consents = cookieConset;
        this.updateGoogleConsents(this.consents);
      } else {
        this.showError = true;
        this.show = false;
      }

      this.show = !cookieConset;
    }
  },
  methods: {
    gtag(_event: string, _typeConstent: string, _consent: ConsentsInt) {
      window.dataLayer.push(arguments);
    },
    updateGoogleConsents(updatedConsents: ConsentsValues) {
      const newArguments: ConsentsInt = {};

      if (updatedConsents.functionality_storage) {
        newArguments.functionality_storage = Consents.GRANTED;
        newArguments.personalization_storage = Consents.GRANTED;
        newArguments.security_storage = Consents.GRANTED;
      } else {
        newArguments.functionality_storage = Consents.DENIED;
        newArguments.personalization_storage = Consents.DENIED;
        newArguments.security_storage = Consents.DENIED;
      }

      if (updatedConsents.Marketing_1) {
        newArguments.analytics_storage = Consents.GRANTED;
      } else {
        newArguments.analytics_storage = Consents.DENIED;
      }

      if (updatedConsents.Marketing_3) {
        newArguments.ad_storage = Consents.GRANTED;
        newArguments.ad_user_data = Consents.GRANTED;
        newArguments.ad_personalization = Consents.GRANTED;

        this.$fb.enable();
      } else {
        newArguments.ad_storage = Consents.DENIED;
        newArguments.ad_user_data = Consents.DENIED;
        newArguments.ad_personalization = Consents.DENIED;
      }

      this.$gtm.init('GTM-NL3FLSN');

      this.gtag(ConsentsEvent.CONSENT, ConsentsEvent.UPDATE, newArguments);
      localStorage.setItem('consents', JSON.stringify(this.consents));
      this.$cookies.set('consents', JSON.stringify(this.consents), {
        path: '/',
        domain: '.tutore.eu',
        sameSite: false,
        maxAge: 60 * 60 * 24 * 7,
      });
    },
    readMore() {
      window.location.href = `${this.$config.websiteUrl}/pl/polityka-prywatnosci-tutore-poland`;
    },
    decline() {
      Object.keys(this.consents).forEach((key: string) => {
        this.consents[key] = false;
      });

      this.show = false;

      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });

      this.updateGoogleConsents(this.consents);
      this.closeDialog();
    },
    acceptAll() {
      Object.keys(this.consents).forEach((key: string) => {
        this.consents[key] = true;
      });

      this.show = false;

      this.updateGoogleConsents(this.consents);
      this.closeDialog();
    },
    custom() {
      this.show = false;

      this.updateGoogleConsents(this.consents);
      this.closeDialog();
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
});
