
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertComponent',
  props: {
    type: {
      type: String,
      default: 'success',
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
});
