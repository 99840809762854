import { CourseType, ProfileType } from '../types/enum';

const sideBarsItems: {
  [key: string]: {
    name: string;
    path: string;
    ico: string;
  }[];
} = {
  [`${ProfileType.STUDENT}_${CourseType.GROUP}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/$(courseId)/homework/',
      ico: 'ico_sidebar_homework.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.QUIZ',
      path: '/dashboard/$(courseId)/quizz/',
      ico: 'ico_sidebar_quizz.svg',
      // number: 4,
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEDETAIL',
      path: '/dashboard/$(courseId)/course-detail/',
      ico: 'ico_sidebar_coursedeail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEPROGRAM',
      path: '/dashboard/$(courseId)/course-program/',
      ico: 'ico_sidebar_courseprogram.svg',
    },
  ],
  [`${ProfileType.STUDENT}_${CourseType.INDIVIDUAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    /* {
      name: 'PAGES.DASHBOARD.SIDEBAR.ABOUT_TEACHER',
      path: '/dashboard/$(courseId)/about-teacher',
      ico: 'ico_sidebar_aboutteacher.svg',
    }, */
  ],
  [`${ProfileType.STUDENT}_${CourseType.TRIAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR',
      path: '/dashboard/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    /* {
      name: 'PAGES.DASHBOARD.SIDEBAR.ABOUT_TEACHER',
      path: '/dashboard/$(courseId)/about-teacher',
      ico: 'ico_sidebar_aboutteacher.svg',
    }, */
  ],

  [`${ProfileType.CLIENT}_${CourseType.GROUP}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/profile/$(profileId)/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/profile/$(profileId)/$(courseId)/homework/',
      ico: 'ico_sidebar_homework.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR',
      path: '/dashboard/profile/$(profileId)/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.QUIZ',
      path: '/dashboard/profile/$(profileId)/$(courseId)/quizz/',
      ico: 'ico_sidebar_quizz.svg',
      // number: 4,
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEDETAIL',
      path: '/dashboard/profile/$(profileId)/$(courseId)/course-detail/',
      ico: 'ico_sidebar_coursedeail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEPROGRAM',
      path: '/dashboard/profile/$(profileId)/$(courseId)/course-program/',
      ico: 'ico_sidebar_courseprogram.svg',
    },
  ],
  [`${ProfileType.CLIENT}_${CourseType.INDIVIDUAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/profile/$(profileId)/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR',
      path: '/dashboard/profile/$(profileId)/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    /* {
      name: 'PAGES.DASHBOARD.SIDEBAR.ABOUT_TEACHER',
      path: '/dashboard/profile/$(profileId)/$(courseId)/about-teacher',
      ico: 'ico_sidebar_aboutteacher.svg',
    }, */
  ],
  [`${ProfileType.CLIENT}_${CourseType.INDIVIDUAL}_STUDENT`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR',
      path: '/dashboard/profile/$(profileId)/$(courseId)/detail/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.ABOUT_TEACHER',
      path: '/dashboard/profile/$(profileId)/$(courseId)/about-teacher/',
      ico: 'ico_sidebar_aboutteacher.svg',
    },
  ],
  [`${ProfileType.CLIENT}_${CourseType.TRIAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/profile/$(profileId)/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR',
      path: '/dashboard/profile/$(profileId)/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    /* {
      name: 'PAGES.DASHBOARD.SIDEBAR.ABOUT_TEACHER',
      path: '/dashboard/profile/$(profileId)/$(courseId)/about-teacher',
      ico: 'ico_sidebar_aboutteacher.svg',
    }, */
  ],
  [`${ProfileType.STUDENT}_${CourseType.INDIVIDUAL_NEW}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DETAIL',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/$(courseId)/homework/',
      ico: 'ico_sidebar_homework.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEDETAIL',
      path: '/dashboard/$(courseId)/course-detail/',
      ico: 'ico_sidebar_coursedeail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEPROGRAM',
      path: '/dashboard/$(courseId)/course-program/',
      ico: 'ico_sidebar_courseprogram.svg',
    },
  ],
  [`${ProfileType.CLIENT}_${CourseType.INDIVIDUAL_NEW}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/profile/$(profileId)/$(courseId)/calendar/',
      ico: 'ico_sidebar_calendar.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.PROGRESS',
      path: '/dashboard/profile/$(profileId)/$(courseId)/progress/',
      ico: 'ico_sidebar_progress.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEPROGRAM',
      path: '/dashboard/profile/$(profileId)/$(courseId)/course-program/',
      ico: 'ico_sidebar_courseprogram.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSEDETAIL',
      path: '/dashboard/profile/$(profileId)/$(courseId)/course-detail/',
      ico: 'ico_sidebar_coursedeail.svg',
    },
  ],
  [ProfileType.TEACHER]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DASHBOARD',
      path: '/dashboard/',
      ico: 'ico_sidebar_dashboard.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.COURSE_GROUP',
      path: '/dashboard/group/',
      ico: 'ico_sidebar_course_group.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.INDIVIDUAL_LESSON',
      path: '/dashboard/individual/',
      ico: 'ico_sidebar_individual_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.TRIAL_LESSON',
      path: '/dashboard/trial-lesson/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.EVALUATIONS',
      path: '/dashboard/evaluations/',
      ico: 'ico_sidebar_evaluations.svg',
    },
  ],
  [`${ProfileType.TEACHER}_${CourseType.GROUP}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DASHBOARD',
      path: '/dashboard/group/$(groupId)/',
      ico: 'ico_sidebar_dashboard.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/group/$(groupId)/homework/',
      ico: 'ico_sidebar_individual_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/group/$(groupId)/calendar/',
      ico: 'ico_sidebar_course_group.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.STUDENTS',
      path: '/dashboard/group/$(groupId)/students/',
      ico: 'ico_sidebar_students.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.SHARED_MATERIALS',
      path: '/dashboard/group/$(groupId)/media/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
  ],
  [`${ProfileType.TEACHER}_${CourseType.INDIVIDUAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DASHBOARD',
      path: '/dashboard/individual/$(individualId)/',
      ico: 'ico_sidebar_dashboard.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.LESSONS',
      path: '/dashboard/individual/$(individualId)/calendar/',
      ico: 'ico_sidebar_course_group.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/individual/$(individualId)/homework/',
      ico: 'ico_sidebar_homework_2.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.LIBRARY',
      path: '/dashboard/individual/$(individualId)/materials/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.SHARED_MATERIALS',
      path: '/dashboard/individual/$(individualId)/media/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
  ],
  [`${ProfileType.TEACHER}_${CourseType.INDIVIDUAL_NEW}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DASHBOARD',
      path: '/dashboard/individual-new/$(individualId)/',
      ico: 'ico_sidebar_dashboard.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/individual-new/$(individualId)/calendar/',
      ico: 'ico_sidebar_course_group.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/individual-new/$(individualId)/homework/',
      ico: 'ico_sidebar_homework_2.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.LIBRARY',
      path: '/dashboard/individual-new/$(individualId)/materials/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.SHARED_MATERIALS',
      path: '/dashboard/individual-new/$(individualId)/media/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.STUDENT_PROFILE',
      path: '/dashboard/individual-new/$(individualId)/student-profile/',
      ico: 'ico_sidebar_students.svg',
    },
  ],
  [`${ProfileType.TEACHER}_${CourseType.TRIAL}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.DASHBOARD',
      path: '/dashboard/individual/$(individualId)/',
      ico: 'ico_sidebar_dashboard.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.CALENDAR_ONLY',
      path: '/dashboard/individual/$(individualId)/calenda/',
      ico: 'ico_sidebar_course_group.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.LIBRARY',
      path: '/dashboard/individual/$(individualId)/materials/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.SHARED_MATERIALS',
      path: '/dashboard/individual/$(individualId)/media/',
      ico: 'ico_sidebar_trial_lesson.svg',
    },
  ],
  [`${ProfileType.STUDENT}_${CourseType.ARCHIVED}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MAIN_PAGE',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/$(courseId)/homework/',
      ico: 'ico_sidebar_homework.svg',
    },
  ],
  [`${ProfileType.CLIENT}_${CourseType.ARCHIVED}`]: [
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MAIN_PAGE',
      path: '/dashboard/$(courseId)/detail/',
      ico: 'ico_sidebar_detail.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.MATERIALS',
      path: '/dashboard/$(courseId)/materials/',
      ico: 'ico_sidebar_materials.svg',
    },
    {
      name: 'PAGES.DASHBOARD.SIDEBAR.HOMEWORK',
      path: '/dashboard/$(courseId)/homework/',
      ico: 'ico_sidebar_homework.svg',
    },
  ],
};

export default sideBarsItems;
