
import { defineComponent } from 'vue';
import { mdiClose } from '@mdi/js';
import DialogCard from '../components/DialogCard.vue';

export default defineComponent({
  name: 'ChangeProfileDialog',
  components: {
    DialogCard,
  },
  data() {
    return {
      mdiClose,
    };
  },
});
