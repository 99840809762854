import { GoogleAuthProvider, User, ParsedToken } from 'firebase/auth';
import { ActionContext, ActionTree } from 'vuex';
import firebase from 'firebase/compat';
import { RootStateStore } from '../rootState';
import { AccountState } from './stateType';
import { ProfileType } from '~/types/enum';
import slugs from '~/locales/slugs';

interface UserCredentials {
  email: string;
  password: string;
}
interface UserInformationSignUp {
  email: string;
  password: string;
  tutor: boolean;
}

const actions: ActionTree<AccountState, RootStateStore> = {
  async signIn(
    { commit }: ActionContext<AccountState, unknown>,
    payload: UserCredentials,
  ): Promise<firebase.auth.UserCredential> {
    try {
      const account = payload
        ? await firebase
            .auth()
            .signInWithEmailAndPassword(payload.email.trim(), payload.password)
            .catch((error) => {
              if (
                JSON.parse(JSON.stringify(error)).code === 'auth/user-disabled'
              ) {
                throw new Error('user disabled');
              } else {
                throw new Error('invalid email or password');
              }
            })
        : await firebase.auth().signInWithPopup(new GoogleAuthProvider());

      if (!account?.user?.emailVerified) {
        await firebase.auth().signOut();
        throw new Error('email not verified');
      }

      this.dispatch('account/commitAccount', { authUser: account?.user });
      await this.$http
        .post('/accounts/login-log', {
          email: account?.user.email,
          type: 'password',
        })
        .catch((error) => console.log('error', error));
      return account;
    } catch (error) {
      commit('setAccount', null);
      return Promise.reject(error);
    }
  },
  async signInWithEmailLink(
    _: ActionContext<AccountState, unknown>,
  ): Promise<firebase.auth.UserCredential> {
    const url = new URL(window.location.href);

    const email = url.searchParams.get('email') || '';

    const account = await firebase
      .auth()
      .signInWithEmailLink(email, window.location.href);

    if (!account.user?.emailVerified) {
      await firebase.auth().signOut();
      throw new Error('email not verified');
    }
    await this.$http
      .post('/accounts/login-log', { email, type: 'magiclink' })
      .catch((error) => console.log('error', error));
    return account;
  },

  async signOut(
    { commit }: ActionContext<AccountState, unknown>,
    payload: string,
  ) {
    await firebase.auth().signOut();
    if (process.client) {
      localStorage.removeItem('currentProfileServices');
      const websiteUrl = process.env.websiteUrl;
      if (websiteUrl) {
        document.cookie = `profile=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${websiteUrl.replace(
          'https://',
          '',
        )}`;
      }
    }

    commit('setAccount', null);
    if (payload === 'register') {
      await this.$router.push(`/${this.localePath('RegisterTeacher')}/`);
    } else if (payload === 'registerUser') {
      await this.$router.push(`/${this.localePath('Register')}/`);
    } else {
      const homeSlugLanguages = slugs.Home.slug;
      window.location.href = `${process.env.websiteUrl}/${this.$i18n.locale}${
        slugs.Home.slug[this.$i18n.locale as keyof typeof homeSlugLanguages]
      }`;
    }
  },

  async signUp(
    { commit },
    payload: UserInformationSignUp,
  ): Promise<firebase.auth.UserCredential> {
    const account = await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password);
    if (payload.tutor) {
      await this.$http
        .post('/tutors/register-db', { uid: account.user?.uid })
        .catch((error) => console.log('error', error));
    }
    await this.$http
      .post('/auth/verify', {
        email: account.user?.email?.trim(),
        ...(payload.tutor && { role: ProfileType.TEACHER }),
      })
      .catch((error) => console.log('error', error));
    await this.$router.push({
      path: this.localePath('RegisterSuccess'),
      params: {
        email: payload.email,
      },
    });
    firebase.auth().signOut();
    return account;
  },

  commitAccount(
    { commit }: ActionContext<AccountState, unknown>,
    payload: { authUser: User & { needRegistationCC: boolean } } | null,
  ): void {
    if (payload?.authUser?.email) {
      const { uid, displayName, email, photoURL, needRegistationCC } =
        payload.authUser;
      commit('setAccount', {
        uid,
        displayName,
        email,
        photoURL,
        needRegistationCC,
      });
    } else {
      commit('setAccount', null);
    }
  },

  async getClaims(): Promise<ParsedToken> {
    if (await firebase.auth().currentUser) {
      return firebase
        .auth()
        .currentUser.getIdTokenResult(true)
        .then((idTokenResult) => idTokenResult.claims);
    }
    return {};
  },

  async setNewPassword(
    _actionContext: ActionContext<AccountState, unknown>,
    {
      actionCode,
      newPassword,
    }: {
      actionCode: string;
      newPassword: string;
    },
  ): Promise<void> {
    try {
      await firebase.auth().verifyPasswordResetCode(actionCode);
    } catch (error) {
      throw new Error('link has expired');
    }
    await firebase.auth().confirmPasswordReset(actionCode, newPassword);
  },

  async verifyEmail(
    _actionContext: ActionContext<AccountState, unknown>,
    {
      actionCode,
    }: {
      actionCode: string;
    },
  ): Promise<void> {
    await firebase.auth().applyActionCode(actionCode);
  },

  async fetchInformations({
    commit,
  }: ActionContext<AccountState, unknown>): Promise<void> {
    try {
      const isInformations = await this.$http
        .get('/accounts')
        .then((response) => {
          const {
            firstName,
            lastName,
            houseNumber,
            town,
            street,
            phone,
            postcode,
          } = response.data;

          if (
            firstName &&
            lastName &&
            houseNumber &&
            town &&
            street &&
            phone &&
            postcode
          ) {
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log('error', error);
          return false;
        });
      if (process.client) {
        localStorage.setItem('isAccountData', isInformations.toString());
      }
      return commit('setIsAccountData', isInformations);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};

export default actions;
