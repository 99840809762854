// time in minutes before lesson start
export const TimeToEnterLesson = 15;
export const TimeToEnterLessonTeacher = 60 * 24 * 365;
export const TimeToChangeSubject = 15;
export const TimeToShowmaterials = 15;

export const TeacherDaysToCancelLesson = 7;
export const ClientDaysToCancelLesson = 1;

export const PriceFormat = 'pl-PL';

export const InfolineTeacher = '+ 48 530 989 510';
export const InfolineClient = '+ 48 500 965 138';
