import { ActionContext, ActionTree } from 'vuex';
import { RootStateStore } from '../rootState';
import { ChoosenService, CourseInformation } from '../../types/interfaces';
import { CourseType } from '../../types/enum';
import { CommonState } from './stateType';

const actions: ActionTree<CommonState, RootStateStore> = {
  redirectIfLoggedIn(
    { commit, rootState }: ActionContext<CommonState, RootStateStore>,
    payload: CourseInformation,
  ): void {
    const { type, id, courseType, isAdult, isAccountData, serviceId } = payload;
    commit('setRedirectFromEnroll', this.$router.currentRoute.path);
    const path = `/${this.$i18n.locale}/enroll${
      type === CourseType.INDIVIDUAL ? '-individual' : ''
    }/${type}${
      serviceId ? `/${serviceId}` : ''
    }/${id}?courseType=${courseType}`;

    if (
      (rootState?.account?.account || isAdult) &&
      type === CourseType.GROUP &&
      courseType === 'trial'
    ) {
      this.$router.push(path);
    } else if (
      rootState?.account?.account &&
      (courseType === courseType.NORMAL || type === CourseType.INDIVIDUAL)
    ) {
      if (!isAccountData) {
        commit('setRedirectTo', path);
        commit('setIsValidated', true);
        this.$router.push(`/${this.$i18n.locale}/payment-form/`);
      } else {
        commit('setIsValidated', false);
        this.$router.push(path);
      }

      // else if (clientProfile.isPinProtected) {
      //   commit('setRedirectTo', path);
      //   this.$router.push(`/enroll-pin/${clientProfile.id}`);
      // } else if (isAccountData) {
      //   commit('setRedirectTo', path);
      //   this.$router.push('/' + this.$root.$i18n.locale + '/enroll-verify-profile-otp');
      // }
      // }
    } else if (
      rootState?.account?.account &&
      (courseType === 'normal' || type === CourseType.INDIVIDUAL) &&
      isAccountData
    ) {
      // eslint-disable-line no-dupe-else-if
      this.$router.push(path);
    } else if (courseType === courseType.TRIAL && type === CourseType.GROUP) {
      const pathToEnrollWithoutAccount = `/${this.$i18n.locale}/enroll-without-account/${id}`;
      commit('setCourseInformation', payload);
      this.$router.push(pathToEnrollWithoutAccount);
    } else {
      commit('setCourseInformation', payload);
      commit('setShowNotLoginDialog', true);
    }
  },
  commitChoosenServices(
    { commit }: ActionContext<CommonState, unknown>,
    payload: ChoosenService[],
  ): void {
    commit('setChoosenServices', payload);
  },
  async setReUseLessons({
    commit,
  }: ActionContext<CommonState, unknown>): Promise<void> {
    try {
      const tmp = await this.$http
        .get('/lessons/individual/cancelled')
        .then((response) => response.data);
      return commit('setReUseLessons', tmp);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};

export default actions;
