import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['@/styles/variables.scss'],
  treeShake: false,
  breakpoint: {
    thresholds: {
      xs: 575,
      sm: 767,
      md: 991,
      lg: 1199,
    },
    scrollBarWidth: 24,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#823189',
        secondary: '#F5EEF6',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
