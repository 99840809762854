import { TimezoneWithKey } from './interfaces';

export const ProfileType = {
  ADMIN: 'admin',
  CLIENT: 'client',
  STUDENT: 'student',
  TEACHER: 'tutor',
};

export const CourseType = {
  GROUP: 'group',
  INDIVIDUAL: 'individual',
  INDIVIDUAL_NEW: 'individual-new',
  TRIAL: 'trial',
  REGULAR: 'regular',
  LESSON_TRIAL: 'lesson_trial',
  NORMAL: 'normal',
  ARCHIVED: 'archived',
  CONTINUES: 'continues',
};

export const CmsComponentsType = {
  CURRICULA_SINGLE: 'curricula.single',
  CURRICULA_MANY: 'curricula.many',
};

export const CourseStatusType = {
  RR: 'rr',
  TT: 'tt',
  ZS: 'zs',
  ZZ: 'zz',
  ZZRR: 'zzrr',
};

export const EnrollStatusType = {
  CHOOSE_DATES: 'chooseDates',
  CHOOSE_SERVICES: 'chooseServices',
  CHOOSE_DATE: 'chooseDate',
};

export const HolidayCourseType = {
  COLONIES: 'colonies',
  VACATION: 'vacation',
};

export const SortType = {
  TIME_ASC: 'TIME_ASC',
  TIME_DESC: 'TIME_DESC',
  TYPE_ASC: 'TYPE_ASC',
  TYPE_DESC: 'TYPE_DESC',
};

export const HomeworkType = {
  QUIZ: 'QUIZ',
  TEST: 'TEST',
};

export const MediaType = {
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  CHAT: 'CHAT',
};

export const QuizStatus = {
  STARTED: 'STARTED',
  NEW: 'NEW',
  ENDED: 'ENDED',
};

export const HomeworkStatus = {
  STARTED: 'STARTED',
  NEW: 'NEW',
  OVERDUE: 'OVERDUE',
  ENDED: 'ENDED',
};

export const NotificationType = {
  AWARD: 'AWARD',
  HOMEWORK: 'HOMEWORK',
  QUIZ: 'QUIZ',
  WEBINAR: 'WEBINAR',
};

export const GlobalNotyficationType = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARN: 'warn',
};

export const BreadCrumbPosition = {
  MAIN: 0,
  PROFILE: 1,
  TEACHER: 2,
  COURSE: 3,
};

export const Cookies = {
  NOTRATED: 'notRated',
  LOCALE: 'locale',
};

export const PlDayNameShortcut = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb', 'Nd'];

export const primarySchoolGrade = [
  {
    display: '1',
    value: 1,
  },
  {
    display: '2',
    value: 2,
  },
  {
    display: '3',
    value: 3,
  },
  {
    display: '4',
    value: 4,
  },
  {
    display: '5',
    value: 5,
  },
  {
    display: '6',
    value: 6,
  },
  {
    display: '7',
    value: 7,
  },
  {
    display: '8',
    value: 8,
  },
];

export const highSchoolGrade = [
  {
    display: '1',
    value: 9,
  },
  {
    display: '2',
    value: 10,
  },
  {
    display: '3',
    value: 11,
  },
  {
    display: '4',
    value: 12,
  },
  {
    display: '5',
    value: 13,
  },
];

export const departments = [
  {
    id: 13,
    name: 'Call Center',
    openingCount: '3',
  },
  {
    id: 14,
    name: 'Edukacja',
    openingCount: '12',
  },
  {
    id: 9,
    name: 'Infolinia',
    openingCount: '1',
  },
  {
    id: 3,
    name: 'IT',
    openingCount: '2',
  },
  {
    id: 15,
    name: 'Partnerzy biznesowi',
    openingCount: '2',
  },
  {
    id: 6,
    name: 'Turystyka',
    openingCount: '1',
  },
];

export const FileExtToType = {
  pdf: 'DOCUMENT',
  doc: 'DOCUMENT',
  docx: 'DOCUMENT',
  jpg: 'IMAGE',
  jpeg: 'IMAGE',
  png: 'IMAGE',
  mp3: 'AUDIO',
};

export const EvaluationCardType = {
  BOTH_DIPLOMA: 'BOTH_DIPLOMA',
  BOTH_PROGRESS_CARD: 'BOTH_PROGRESS_CARD',
  DIPLOMA: 'DIPLOMA',
  PROGRESS_CARD: 'PROGRESS_CARD',
};

export const LessonDurationData = {
  25: '25',
  50: '50',
};

export interface Video {
  id?: string;
  url: string;
  thumb: string;
}

export const PaymentStatus = {
  COMPLETE: 'complete',
  PENDING: 'pending',
};

export const PaymentDateStatus = {
  NEW: 'NEW',
  OVERDUE: 'OVERDUE',
};

export const PaymentPeroidType = {
  MONTH: 'MONTH',
  SEMESTER: 'SEMESTER',
  YEAR: 'YEAR',
};

export const PaymentPeriodType = {
  ONE_MONTH: 'oneMonth',
  SEMESTER: 'semester',
  ALL_MONTH: 'allMonth',
};

export const PaymentFinishType = {
  SUCCESS: 'success',
  FAIL: 'fail',
  FAILED: 'failed',
};

export const PaymentFrequencyType = {
  MONTH: 'month',
  MONTHLY: 'monthly',
  SEMESTER: 'semester',
  FULL: 'full',
  YEAR: 'year',
  THREE_MONTH: 'threeMonth',
};

export const PaymentMethodType = {
  EVERY_MONTH: 'Płatność co miesiąc',
  SEMESTER: 'Płatność semestralna',
  UP_FRONT: 'Płatność z góry za cały kurs',
};

export const OnboardingBoxName = {
  BASIC_INFORMATION: 'basicInformation',
  EDUCATION: 'education',
  EXPERIENCE: 'experience',
  CERTIFICATE: 'certificate',
  SERVICES: 'services',
  MEDIA: 'media',
  VIDEO: 'video',
  PROFILE: 'profile',
  HOBBIES: 'hobbies',
  AVAILABILITY: 'availability',
  AVAILABILITY_EDIT: 'availability_edit',
};

export const OnboardingIncorrectDetail = {
  WRONG_COURSE: 'wrong course',
  MISSING_COURSE: 'missing course',
  OTHER: 'other',
};

export const OnboardingAction = {
  EDIT: 'edit',
};

export enum ONBOARDING_STATUS {
  STARTED = 'rozpoczęty',
  FILLED = 'wypełniony',
  ACCEPTED = 'zaakceptowany',
  FIXED = 'poprawiony',
  TO_BE_FIXED = 'do poprawy',
}

export const COUNTRY_DIAL_CODE = {
  AF: '+93',
  AX: '+358',
  AL: '+355',
  DZ: '+213',
  AS: '+1684',
  AD: '+376',
  AO: '+244',
  AI: '+1264',
  AQ: '+672',
  AG: '+1268',
  AR: '+54',
  AM: '+374',
  AW: '+297',
  AU: '+61',
  AT: '+43',
  AZ: '+994',
  BS: '+1242',
  BH: '+973',
  BD: '+880',
  BB: '+1246',
  BY: '+375',
  BE: '+32',
  BZ: '+501',
  BJ: '+229',
  BM: '+1441',
  BT: '+975',
  BO: '+591',
  BA: '+387',
  BW: '+267',
  BR: '+55',
  IO: '+246',
  BN: '+673',
  BG: '+359',
  BF: '+226',
  BI: '+257',
  KH: '+855',
  CM: '+237',
  CA: '+1',
  CV: '+238',
  KY: '+ 345',
  CF: '+236',
  TD: '+235',
  CL: '+56',
  CN: '+86',
  CX: '+61',
  CC: '+61',
  CO: '+57',
  KM: '+269',
  CG: '+242',
  CD: '+243',
  CK: '+682',
  CR: '+506',
  CI: '+225',
  HR: '+385',
  CU: '+53',
  CY: '+357',
  CZ: '+420',
  DK: '+45',
  DJ: '+253',
  DM: '+1767',
  DO: '+1849',
  EC: '+593',
  EG: '+20',
  SV: '+503',
  GQ: '+240',
  ER: '+291',
  EE: '+372',
  ET: '+251',
  FK: '+500',
  FO: '+298',
  FJ: '+679',
  FI: '+358',
  FR: '+33',
  GF: '+594',
  PF: '+689',
  GA: '+241',
  GM: '+220',
  GE: '+995',
  DE: '+49',
  GH: '+233',
  GI: '+350',
  GR: '+30',
  GL: '+299',
  GD: '+1473',
  GP: '+590',
  GU: '+1671',
  GT: '+502',
  GG: '+44',
  GN: '+224',
  GW: '+245',
  GY: '+595',
  HT: '+509',
  VA: '+379',
  HN: '+504',
  HK: '+852',
  HU: '+36',
  IS: '+354',
  IN: '+91',
  ID: '+62',
  IR: '+98',
  IQ: '+964',
  IE: '+353',
  IM: '+44',
  IL: '+972',
  IT: '+39',
  JM: '+1876',
  JP: '+81',
  JE: '+44',
  JO: '+962',
  KZ: '+77',
  KE: '+254',
  KI: '+686',
  KP: '+850',
  KR: '+82',
  KW: '+965',
  KG: '+996',
  LA: '+856',
  LV: '+371',
  LB: '+961',
  LS: '+266',
  LR: '+231',
  LY: '+218',
  LI: '+423',
  LT: '+370',
  LU: '+352',
  MO: '+853',
  MK: '+389',
  MG: '+261',
  MW: '+265',
  MY: '+60',
  MV: '+960',
  ML: '+223',
  MT: '+356',
  MH: '+692',
  MQ: '+596',
  MR: '+222',
  MU: '+230',
  YT: '+262',
  MX: '+52',
  FM: '+691',
  MD: '+373',
  MC: '+377',
  MN: '+976',
  ME: '+382',
  MS: '+1664',
  MA: '+212',
  MZ: '+258',
  MM: '+95',
  NA: '+264',
  NR: '+674',
  NP: '+977',
  NL: '+31',
  AN: '+599',
  NC: '+687',
  NZ: '+64',
  NI: '+505',
  NE: '+227',
  NG: '+234',
  NU: '+683',
  NF: '+672',
  MP: '+1670',
  NO: '+47',
  OM: '+968',
  PK: '+92',
  PW: '+680',
  PS: '+970',
  PA: '+507',
  PG: '+675',
  PY: '+595',
  PE: '+51',
  PH: '+63',
  PN: '+872',
  PL: '+48',
  PT: '+351',
  PR: '+1939',
  QA: '+974',
  RO: '+40',
  RU: '+7',
  RW: '+250',
  RE: '+262',
  BL: '+590',
  SH: '+290',
  KN: '+1869',
  LC: '+1758',
  MF: '+590',
  PM: '+508',
  VC: '+1784',
  WS: '+685',
  SM: '+378',
  ST: '+239',
  SA: '+966',
  SN: '+221',
  RS: '+381',
  SC: '+248',
  SL: '+232',
  SG: '+65',
  SK: '+421',
  SI: '+386',
  SB: '+677',
  SO: '+252',
  ZA: '+27',
  SS: '+211',
  GS: '+500',
  ES: '+34',
  LK: '+94',
  SD: '+249',
  SR: '+597',
  SJ: '+47',
  SZ: '+268',
  SE: '+46',
  CH: '+41',
  SY: '+963',
  TW: '+886',
  TJ: '+992',
  TZ: '+255',
  TH: '+66',
  TL: '+670',
  TG: '+228',
  TK: '+690',
  TO: '+676',
  TT: '+1868',
  TN: '+216',
  TR: '+90',
  TM: '+993',
  TC: '+1649',
  TV: '+688',
  UG: '+256',
  UA: '+380',
  AE: '+971',
  GB: '+44',
  US: '+1',
  UY: '+598',
  UZ: '+998',
  VU: '+678',
  VE: '+58',
  VN: '+84',
  VG: '+1284',
  VI: '+1340',
  WF: '+681',
  YE: '+967',
  ZM: '+260',
  ZW: '+263',
};

export const CURRENCY_TYPE = {
  PLN: 'ZŁ',
  EUR: '€',
  USD: '$',
  GFB: '£',
};

export const CURRENCY_CODE = [
  {
    code: 'PLN',
    sign: 'ZŁ',
  },
  {
    code: 'EUR',
    sign: '€',
  },
  {
    code: 'USD',
    sign: '$',
  },
  {
    code: 'GFB',
    sign: '£',
  },
];

export interface Enum {
  [id: number]: string;
}

export const timezonesManila = [
  'Asia/Manila',
  'Asia/Singapore',
  'Asia/Irkutsk',
  'Asia/Shanghai',
  'Australia/Perth',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
];

export const timezones: TimezoneWithKey[] = [
  {
    key: 'Europe/Warsaw',
    text: 'PL - Warsaw',
    displayText: 'PL - Warsaw',
  },
  {
    key: 'Asia/Manila',
    text: 'PH - Manila',
    displayText: 'PH - Manila',
  },
];

export enum TIMEZONE {
  EUROPE_WARSAW = 'Europe/Warsaw',
  ASIA_MANILA = 'Asia/Manila',
}

export enum MaterialType {
  HOMEWORK = 'Homework',
  GAME = 'Game',
  MATERIALS_AFTER = 'MaterialsAfter',
  MATERIALS = 'Materials',
  EXERCISES = 'Exercises',
  PRESENTATION = 'Presentation',
}

export const seoURLPl = {
  'programowanie-minecraft-dla-dzieci-mlodziezy': 5,
  'logiczna-przygoda-minecraft-wakacje': 35,
  'logiczna-przygoda-minecraft-polkolonie': 36,
  'jak-stworzyc-gre-2D-w-Godot-Engine': 41,
  'kurs-rysunku-dla-dzieci': 9,
  'nauka-rysowania-dla-dzieci': 9,
  'digital-painting-dla-dzieci': 42,
  'kurs-rysunku-dla-doroslych': 27,
  'angielski-dla-dziecka-lets-talk-wakacje': 34,
  'angielski-dla-dziecka-lets-talk-polkolonie': 38,
  'angielski-dla-dziecka-pearson': 7,
  'egzamin-osmoklasisty-angielski-kurs-semestralny': 15,
  'egzamin-osmoklasisty-angielski-kurs-intensywny': 29,
  'angielski-dla-dziecka-lets-talk': 12,
  'matematyka-dla-dzieci-online': 6,
  'egzamin-osmoklasisty-matematyka-kurs-semestralny': 14,
  'egzamin-osmoklasisty-matematyka-kurs-intensywny': 28,
  'matematyka-dla-dzieci-minecraft': 11,
  'matematyka-dla-dzieci-minecraft-wakacje': 35,
  'matematyka-dla-dzieci-minecraft-polkolonie': 39,
  'matura-polski-podstawowy': 57,
  'matura-angielski-podstawowy': 54,
  'matura-matematyka-podstawowy': 59,
  'grafika-komputerowa-online-dla-dzieci': 8,
  'web-designer-marketignowiec': 20,
  'fotografik-wirtualny-rysownik': 21,
  'grafika-3d-architekt': 43,
  'grafika-komputerowa-online-dla-doroslych': 24,
  'grafika-komputerowa-online-instagrafik-polkolonie': 37,
  'grafika-komputerowa-online-instagrafik-wakacje': 33,
  'nauka-gry-w-szachy-online': 16,
  'nauka-gry-na-gitarze-dla-dzieci': 4,
  'tworzenie-muzyki-dla-dzieci-mlodziezy': 31,
  'gitara-dla-poczatkujacych': 60,
  'egzamin-osmoklasisty-polski-kurs-semestralny': 22,
  'egzamin-osmoklasisty-polski-kurs-intensywny': 30,
} as Record<string, number | null | undefined>;

export enum LeadOfferType {
  CUSTOM = 'custom',
  NEW_CUSTOM = 'new_custom',
  MATURA = 'matura',
}

export enum ServiceCardSizeType {
  NORMAL = 'normal',
  SMALL = 'small',
}

export enum ColorName {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLUEBLACK = 'blueblack',
}

export enum SocialsType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  MESSENGER = 'messenger',
  YOUTUBE = 'youtube',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
}

export const UTM_SOURCE = {
  REFER_PROGRAM: 'program_polecen',
};

export const MENU_HEADER_TYPE = {
  ADULT: 'Adult',
  STUDENT: 'Student',
  BLOG: 'Blog',
};

export enum TIMER_LINE_STYLE {
  ONE_LINE = 'one_line',
  TWO_LINES = 'two_lines',
}

export enum TIMER_TEXT_STYLE {
  SHORT = 'short',
  LONG = 'long',
}

export enum TIMER_KEY {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
}

export enum FLEX_DIRECTION {
  ROW = 'row',
  COLUMN = 'column',
}

export enum PROVIDER_TYPE {
  MAINPAGE = 'mainpage',
}

export enum COMPANY_OWNER {
  TUTORE = 'tutore',
  TUTORE_POLAND = 'tutore_poland',
}

export enum TEACHER_EXPERIENCE {
  LESS_YEAR = 'lessThanYear',
  MORE_YEAR = 'moreThanYear',
}

export enum LOCALE_LANGUAGE {
  PL = 'pl',
  EN = 'en',
}

export enum QuestionType {
  CLOSED = 'closed',
  OPEN = 'open',
}

export enum TimeOfDay {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export enum IndividualPaymentType {
  MONTHLY = 'monthly',
  FULL = 'full',
}

export enum IndividualFinishPaymentStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  SUCCESS_TRIAL = 'success-trial',
  SUCCESS_MONTH = 'success-month',
}

export enum AgeGrade {
  CHILDREN = 'children',
  ADULTS = 'adults',
}

export enum LessonFilterOption {
  ALL = 'All',
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
  ENDED = 'Ended',
}

export enum CalendarView {
  WEEK = 'Week',
  MONTH = 'Month',
}

export enum NATIVE_LANGUAGE {
  POLISH_PL = 'Polski',
  POLISH_EN = 'Polish',
  ENGLISH_PL = 'Angielski',
  ENGLISH_EN = 'English',
  GERMAN_PL = 'Niemiecki',
  CZECH_PL = 'Czeski',
  RUSSIAN_PL = 'Rosyjski',
  FRENCH_PL = 'Francuski',
  ITALIAN_PL = 'Włoski',
  SPANISH_PL = 'Hiszpański',
  UKRAINIAN_PL = 'Ukraiński',
}

export enum LESSON_PROPERTY {
  START = 'start',
  END = 'end',
}

export enum StepDescriptionStatus {
  NOT_LOGGED_POLL_REQUIRED = 'notLoggedPollRequired',
  NOT_LOGGED_POLL_NOT_REQUIRED = 'notLoggedPollNotRequired',
  LOGGED_POLL_REQUIRED = 'loggedPollRequired',
  LOGGED_POLL_NOT_REQUIRED = 'loggedPollNotRequired',
}

export enum StepType {
  NAME = 'name',
  PROFILE = 'profile',
  QUESTION = 'question',
  DATE = 'date',
  TEACHER = 'teacher',
  SCHEDULE = 'schedule',
  FORM = 'form',
  SUMMARY = 'summary',
}

export enum StepTypeBuy {
  NAME = 'name',
  PROFILE = 'profile',
  QUESTION = 'question',
  DATE = 'date',
  TEACHER = 'teacher',
  FORM = 'form',
  SUMMARY = 'summary',
  PACKAGE = 'package',
  SCHEDULE_BUY_PACKAGE = 'scheduleBuyPackage',
}

export enum TimeFormat {
  HOUR_12 = '12',
  HOUR_24 = '24',
  HOUR_MINUTE_24H = 'HH:mm',
  HOUR_12H = 'h a',
  HOUR_MINUTE_12H = 'h:mm a',
}

export enum BackendMessageErrorType {
  EMAIL_EXISTS = 'The email address is already in use by another account.',
}

export enum ILessonFilterValue {
  ALL = 'all',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  STUDENTSA_Z = 'studentsAZ',
  STUDENTSZ_A = 'studentsZA',
}

export enum LESSON_TYPE {
  NEW = 'new',
}

export enum PAYMENT_RETURN_TYPE {
  PROVIDE_TO_PAYMENT = 'provideToPayment',
  MONTHLY_PAYMENT = 'monthlyPayment',
}

export enum TicketStatusType {
  NEW = 'new',
  PENDING = 'pending',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}

export enum ViewType {
  ENROLL = 'enroll',
  DASHBOARD = 'dashboard',
  DASHBOARD_RENEWAL = 'dashboard-renewal',
  PROFILE = 'profile',
}

export enum SuggestedType {
  CC = 'CC',
  SUGGESTED = 'SUGGESTED',
}

export enum ChangeTutorAvailabilityType {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum PartType {
  Text = 'text',
  Bold = 'bold',
  Link = 'link',
  Br = 'br',
}

export enum DATE_FORMAT {
  DD_MM_YYYY = 'dd-mm-yyyy',
  YYYY_MM_DD = 'yyyy-mm-dd',
  DD_MMM_YYYY_HH_MM = 'dd mmm yyyy (hh:mm)',
  DD_MMM_DW_YYYY = 'dd-mmm-dw-yyyy',
  DD_MMM_YYYY = 'dd-mmm-yyyy',
}

export enum TopicStatusType {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum TopicFilterType {
  COURSE = 'course',
  GRADE = 'grade',
  UNIT = 'unit',
  STATUS = 'status',
  TOPIC = 'topic',
}
