import { ActionContext, ActionTree } from 'vuex';
import { RootStateStore } from '../rootState';
import { ServicesAvailabilityState } from './stateType';

const actions: ActionTree<ServicesAvailabilityState, RootStateStore> = {
  async fetchServicesAvailability({
    commit,
  }: ActionContext<ServicesAvailabilityState, unknown>): Promise<void> {
    try {
      const servicesAvailability = await this.$http
        .get('/tutors/services-availability')
        .then((response) => response.data);
      return commit('setServicesAvailability', servicesAvailability);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};

export default actions;
