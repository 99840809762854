
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessageBlock',
  components: {
    // CancelMessageDialog: () => import('../components/CancelMessageDialog.vue'),
    // Alert: () => import('../components/Alert.vue')
  },
  data() {
    return {
      valid: false,
      showMessage: false,
      message: '',
      showCancelMessageDialog: false,
      loading: false,
      alertShow: false,
      alertMessage: '',
      alertType: '',
      rules: {
        message: (v: string) =>
          !!v || this.$t('COMPONENTS.MESSENGER.MESSAGE_REQUIRE').toString(),
      },
    };
  },
  methods: {
    test() {
      this.showMessage = !this.showMessage;
    },
    isMessageCanceled(isCanceled: boolean) {
      if (isCanceled) {
        this.$emit('cancelMessage');
      }
      this.showCancelMessageDialog = false;
    },
    activeAlert(randomChangeSuccessSendMessage: boolean) {
      this.alertShow = true;
      this.alertType = randomChangeSuccessSendMessage ? 'success' : 'error';
      this.alertMessage = randomChangeSuccessSendMessage
        ? this.$t('COMPONENTS.MESSENGER.MESSAGE_SENDED').toString()
        : this.$t('COMPONENTS.MESSENGER.ERROR_MESSAGE').toString();
      window.setTimeout(() => {
        this.alertShow = false;
      }, 3000);
    },
    sendMessage() {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (this.valid) {
        this.loading = true;
        const randomChangeSuccessSendMessage = Math.random() < 0.5;
        window.setTimeout(() => {
          this.activeAlert(randomChangeSuccessSendMessage);
          this.loading = false;
        }, 1000);
      }
    },
  },
});
