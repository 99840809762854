/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionContext, ActionTree } from 'vuex/types/index';
import { RootStateStore } from './rootState';

export const actions: ActionTree<RootStateStore, RootStateStore> = {
  nuxtServerInit(
    { commit }: ActionContext<RootStateStore, RootStateStore>,
    { res },
  ): void {
    if (res && res.locals && res.locals.user) {
      const {
        allClaims: claims,
        idToken: token,
        ...authUser
      } = res.locals.user;
      // commit('account/setAccount', authUser)
    }
  },
};
