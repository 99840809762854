
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LocaleChanger',
  props: {
    color: {
      type: String,
      default: 'blueblack',
    },
  },
  data() {
    return {
      open: false,
      disableLangSw: false,
    };
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(
        (i: { code: any }) => i.code !== this.$i18n.locale,
      );
    },
    routeQueryLength(): number {
      return Object.keys(this.$route.query).length;
    },
    activeLocale(): string {
      return `${this.$nuxt.$i18n.locale} ${this.open ? '▴' : '▾'}`;
    },
  },
  watch: {
    $route() {},
  },
  methods: {},
});
