import { MutationTree } from 'vuex/types/index';
import { set } from '../../utils/vuex';
import { ProfileState } from './stateType';

const mutations: MutationTree<ProfileState> = {
  setProfileId: set('profileId'),
  setProfile: set('profile'),
  setProfiles: set('profiles'),
  setEnrollProfile: set('enrollProfile'),
  setEnrollParent: set('enrollParent'),
  setPassword: set('password'),
};

export default mutations;
