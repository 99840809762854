import { MutationTree } from 'vuex/types/index';
import { set } from '../../utils/vuex';
import { ServicesAvailabilityState } from './stateType';

const mutations: MutationTree<ServicesAvailabilityState> = {
  setServicesAvailability: set<ServicesAvailabilityState>(
    'servicesAvailability',
  ),
};

export default mutations;
