
import { defineComponent } from 'vue';
import { mdiChevronDown } from '@mdi/js';
import SafeHtml from '../SafeHtml.vue';

export default defineComponent({
  name: 'CookieAccordion',
  components: {
    SafeHtml,
  },
  props: {
    consent: {
      type: String,
      required: true,
    },
    consents: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    cookieEnabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    cookies: {
      type: [Boolean, Array],
      required: true,
    },
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      expand: false,
      mdiChevronDown,
      customConsents: this.consents,
      cookieValue: this.value,
    };
  },
  methods: {
    changeState() {
      this.expand = !this.expand;
    },
    onChange($event, consent) {
      this.customConsents[consent] = $event;
      this.$emit('changeState', this.customConsents);
    },
  },
});
