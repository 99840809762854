
import { defineComponent } from 'vue';
import { mdiClose } from '@mdi/js';

export default defineComponent({
  name: 'CancelMessageDialog',
  components: {
    DialogCard: () => import('../components/DialogCard.vue'),
  },
  data() {
    return {
      mdiClose,
    };
  },
});
