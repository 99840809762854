import { render, staticRenderFns } from "./CookieAccordion.vue?vue&type=template&id=f114e034&scoped=true&"
import script from "./CookieAccordion.vue?vue&type=script&lang=ts&"
export * from "./CookieAccordion.vue?vue&type=script&lang=ts&"
import style0 from "./CookieAccordion.vue?vue&type=style&index=0&id=f114e034&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f114e034",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SafeHtml: require('/home/node/app/components/SafeHtml.vue').default})
