import { render, staticRenderFns } from "./LocaleChanger.vue?vue&type=template&id=35c78340&scoped=true&"
import script from "./LocaleChanger.vue?vue&type=script&lang=ts&"
export * from "./LocaleChanger.vue?vue&type=script&lang=ts&"
import style0 from "./LocaleChanger.vue?vue&type=style&index=0&id=35c78340&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c78340",
  null
  
)

export default component.exports