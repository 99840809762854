export default [
  {
    id: 'functionality_storage',
    title:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE_TITLE',
    description:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE_DESCRIPTION',
    cookies: [
      {
        name: 'common/frequencyOfPayments',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_1',
      },
      {
        name: 'common/pickedDate',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_2',
      },
      {
        name: 'common/enrollProfile',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_3',
      },
      {
        name: 'common/token',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_4',
      },
      {
        name: 'common/setEnrollCourseInformation',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_5',
      },
      {
        name: 'common/setCourseInformation',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_6',
      },
      {
        name: 'common/showEnrollTimer',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_7',
      },
      {
        name: 'common/minutesUsed',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_8',
      },
      {
        name: 'common/enrollData',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_9',
      },
      {
        name: 'common/price',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_10',
      },
      {
        name: 'common/total',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_11',
      },
      {
        name: 'common/setCalendarData',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_12',
      },
      {
        name: 'common/setCourseType',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_13',
      },
      {
        name: 'common/setChoosenServices',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_14',
      },
      {
        name: 'notRated',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_15',
      },
      {
        name: 'common/seconds',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_16',
      },
      {
        name: 'offerFilter',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_17',
      },
      {
        name: 'profile/profiles',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_18',
      },
      {
        name: 'isAccountData',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_19',
      },
      {
        name: 'profile',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_20',
      },
      {
        name: 'account/isTutor',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_21',
      },
      {
        name: 'account/isTutorCompleteRegistration',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_22',
      },
      {
        name: 'locale',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_23',
      },
      {
        name: 'i18n_redirected',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_3',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_23',
      },
      {
        name: 'currentProfileServices',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_24',
      },
      {
        name: 'actionbot_uid',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_4',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.TYPE_25',
      },
    ],
    required: true,
    value: true,
  },
  {
    id: 'Marketing_1',
    title:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY_TITLE',
    description:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY_DESCRIPTION',
    cookies: [
      {
        name: 'ga/tags',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_2',
      },
      {
        name: '_ga',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_4',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_4',
      },
      {
        name: '_gid',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.VALIDITY_2',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_5',
      },
      {
        name: 'ga/landingUrl',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONALITY_STORAGE.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_1',
      },
    ],
    value: false,
  },
  {
    id: 'Marketing_2',
    title:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONAL_TITLE',
    description:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.FUNCTIONAL_DESCRIPTION',
    cookies: [
      {
        name: 'smuuid',
        validity: 'n/a',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_6',
      },
      {
        name: 'smclient',
        validity: 'n/a',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_7',
      },
    ],
    value: false,
  },
  {
    id: 'Marketing_3',
    title: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.MARKETING_TITLE',
    description:
      'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.MARKETING_DESCRIPTION',
    cookies: [
      {
        name: '_gcl_au',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_3',
      },
      {
        name: '_fbp',
        validity:
          'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.VALIDITY_1',
        type: 'SELECTION_OF_COOKIE.ACCORDION.SECOND.COOKIES_ARRAY.EFFICIENCY.TYPE_5',
      },
    ],
    value: false,
  },
];
