export default {
  Home: {
    slug: {
      pl: '',
      en: '',
    },
    component: 'views/Home.vue',
  },
  About: {
    slug: {
      pl: '/o-nas',
      en: '/about-us',
    },
    component: 'views/About.vue',
  },
  Help: {
    slug: {
      pl: '/kontakt',
      en: '/contact',
    },
    component: 'views/Help.vue',
  },
  PrivacyPolicy: {
    slug: {
      pl: '/polityka-prywatnosci',
      en: '/privacy-policy',
    },
    component: 'views/PrivacyPolicy.vue',
  },
  HomeTeacher: {
    slug: {
      pl: '/kariera',
      en: '/career',
    },
    component: 'views/HomeTeacher.vue',
  },
  Documents: {
    slug: {
      pl: '/dokumenty',
      en: '/documents',
    },
    component: 'views/Documents.vue',
  },
  OfferGroup: {
    slug: {
      pl: '/kursy/grupowe',
      en: '/courses/groups',
    },
    component: 'views/Offer.vue',
    props: {
      type: 'group',
    },
  },
  OfferIndividual: {
    slug: {
      pl: '/kursy/indywidualne',
      en: '/courses/individual',
    },
    component: 'views/Offer.vue',
    props: {
      type: 'individual',
    },
  },
  ServiceTeacher: {
    slug: {
      pl: '/tutor/:tutorServiceId',
      en: '/tutor/:tutorServiceId',
    },
    component: 'views/ServiceTeacher.vue',
  },
  Holiday: {
    slug: {
      pl: '/holiday',
      en: '/holiday',
    },
    component: 'views/Holiday.vue',
  },
  Login: {
    slug: {
      pl: '/login',
      en: '/login',
    },
    component: 'views/Login.vue',
  },
  Statement: {
    slug: {
      pl: '/statement',
      en: '/statement',
    },
    component: 'views/Statement.vue',
  },
  ActionLink: {
    slug: {
      pl: '/action-link',
      en: '/action-link',
    },
    component: 'views/ActionLink.vue',
  },
  AccountRecovery: {
    slug: {
      pl: '/account-recovery',
      en: '/account-recovery',
    },
    component: 'views/AccountRecovery.vue',
  },
  AccountNewPassword: {
    slug: {
      pl: '/account-new-password',
      en: '/account-new-password',
    },
    component: 'views/AccountNewPassword.vue',
  },
  Register: {
    slug: {
      pl: '/register/:type?/:courseId?',
      en: '/register/:type?/:courseId?',
    },
    component: 'views/Register.vue',
  },
  RegisterTeacher: {
    slug: {
      pl: '/register/teacher',
      en: '/register/teacher',
    },
    component: 'views/RegisterTeacher.vue',
  },
  RegisterCC: {
    slug: {
      pl: '/enter',
      en: '/enter',
    },
    component: 'views/RegisterCC.vue',
  },
  RegisterCCOld: {
    slug: {
      pl: '/register/cc',
      en: '/register/cc',
    },
    component: 'views/RegisterCCOld.vue',
  },
  RegisterSuccess: {
    slug: {
      pl: '/register/success',
      en: '/register/success',
    },
    component: 'views/RegisterSuccess.vue',
  },
  RegisterFail: {
    slug: {
      pl: '/register/fail',
      en: '/register/fail',
    },
    component: 'views/RegisterFail.vue',
  },
  Profiles: {
    slug: {
      pl: '/profiles',
      en: '/profiles',
    },
    component: 'views/Profiles.vue',
  },
  ProfilesTestRenewal: {
    slug: {
      pl: '/profiles/renewal-test',
      en: '/profiles/renewal-test',
    },
    component: 'views/ProfilesTestRenewal.vue',
  },
  ProfileAdd: {
    slug: {
      pl: '/profile-add',
      en: '/profile-add',
    },
    component: 'views/ProfileAdd.vue',
  },
  SetPin: {
    slug: {
      pl: '/profiles/set-pin/:id',
      en: '/profiles/set-pin/:id',
    },
    component: 'views/Pins/SetPin.vue',
  },
  InsertPin: {
    slug: {
      pl: '/profiles/insert-pin',
      en: '/profiles/insert-pin',
    },
    component: 'views/Pins/InsertPin.vue',
  },
  DeletePin: {
    slug: {
      pl: '/profiles/delete-pin/:id',
      en: '/profiles/delete-pin/:id',
    },
    component: 'views/Pins/DeletePin.vue',
  },
  ConfirmData: {
    slug: {
      pl: '/profiles/confirm-data',
      en: '/profiles/confirm-data',
    },
    component: 'views/Pins/ConfirmData.vue',
  },
  ServiceOffer1: {
    slug: {
      pl: '/kurs/jak-stworzyc-gre-2D-w-Godot-Engine',
      en: '/course/how-to-create-game-with-godot-engine',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 41,
    },
  },
  ServiceOffer2: {
    slug: {
      pl: '/kurs/digital-painting-dla-dzieci',
      en: '/course/digital-painting-for-kids',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 42,
    },
  },
  ServiceOffer3: {
    slug: {
      pl: '/kurs/nauka-gry-na-gitarze-dla-dzieci',
      en: '/course/guitar-lessons',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 4,
    },
  },
  ServiceOffer4: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-angielski-kurs-semestralny',
      en: '/course/eighth-grade-exam-english',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 15,
    },
  },
  ServiceOffer5: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-matematyka-kurs-semestralny',
      en: '/course/eighth-grade-exam-maths',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 14,
    },
  },
  ServiceOffer6: {
    slug: {
      pl: '/kurs/matura-angielski-podstawowy',
      en: '/course/matura-exam-english-basic',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 54,
    },
  },
  ServiceOffer7: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-polski-kurs-semestralny',
      en: '/course/eighth-grade-exam-polish',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 22,
    },
  },
  ServiceOffer8: {
    slug: {
      pl: '/kurs/digital-painting-dla-dzieci',
      en: '/kurs/digital-painting-dla-dzieci',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 42,
    },
  },
  ServiceOffer9: {
    slug: {
      pl: '/kurs/programowanie-minecraft-dla-dzieci-mlodziezy',
      en: '/course/coding-with-minecraft',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 5,
    },
  },
  ServiceOffer10: {
    slug: {
      pl: '/kurs/matematyka-dla-dzieci-online',
      en: '/course/maths-for-kids-and-teens',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 6,
    },
  },
  ServiceOffer11: {
    slug: {
      pl: '/kurs/angielski-dla-dziecka-pearson',
      en: '/course/english-for-kids',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 7,
    },
  },
  ServiceOffer12: {
    slug: {
      pl: '/kurs/matura-matematyka-podstawowy',
      en: '/course/matura-exam-maths-basic',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 59,
    },
  },
  ServiceOffer13: {
    slug: {
      pl: '/kurs/grafika-komputerowa-online-dla-dzieci',
      en: '/course/graphic-design-classes-for-kids',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 8,
    },
  },
  ServiceOffer14: {
    slug: {
      pl: '/kurs/kurs-rysunku-dla-dzieci',
      en: '/course/drawing-lessons-for-kids',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 9,
    },
  },
  ServiceOffer15: {
    slug: {
      pl: '/kurs/matematyka-dla-dzieci-minecraft',
      en: '/course/maths-with-minecraft-for-kids',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 11,
    },
  },
  ServiceOffer16: {
    slug: {
      pl: '/kurs/angielski-dla-dziecka-lets-talk',
      en: '/course/english-for-kids-lets-talk',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 12,
    },
  },
  ServiceOffer17: {
    slug: {
      pl: '/kurs/nauka-gry-w-szachy-online',
      en: '/course/chess-lessons',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 16,
    },
  },
  ServiceOffer18: {
    slug: {
      pl: '/kurs/grafika-komputerowa-online-dla-doroslych',
      en: '/course/graphic-design-classes-for-adults',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 24,
    },
  },
  ServiceOffer19: {
    slug: {
      pl: '/nauka-angielskiego-online',
      en: '/nauka-angielskiego-online',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 26,
    },
  },
  ServiceOffer20: {
    slug: {
      pl: '/kurs/kurs-rysunku-dla-doroslych',
      en: '/course/drawing-lessons-for-adults',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 27,
    },
  },
  ServiceOffer21: {
    slug: {
      pl: '/kurs/tworzenie-muzyki-dla-dzieci-mlodziezy',
      en: '/course/making-music-for-kids-and-teens',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 31,
    },
  },
  ServiceOffer22: {
    slug: {
      pl: '/kurs/matura-polski-podstawowy',
      en: '/course/matura-exam-polish-basic',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 57,
    },
  },
  ServiceOffer23: {
    slug: {
      pl: '/logiczna-przygoda-minecraft-wakacje',
      en: '/logiczna-przygoda-minecraft-wakacje',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 32,
    },
  },
  ServiceOffer24: {
    slug: {
      pl: '/grafika-komputerowa-online-instagrafik-wakacje',
      en: '/grafika-komputerowa-online-instagrafik-wakacje',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 33,
    },
  },
  ServiceOffer25: {
    slug: {
      pl: '/angielski-dla-dziecka-lets-talk-wakacje',
      en: '/angielski-dla-dziecka-lets-talk-wakacje',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 34,
    },
  },
  ServiceOffer26: {
    slug: {
      pl: '/matematyka-dla-dzieci-minecraft-wakacje',
      en: '/matematyka-dla-dzieci-minecraft-wakacje',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 35,
    },
  },
  ServiceOffer27: {
    slug: {
      pl: '/kurs/web-designer-marketignowiec',
      en: '/kurs/web-designer-marketignowiec',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 20,
    },
  },
  ServiceOffer28: {
    slug: {
      pl: '/kurs/fotografik-wirtualny-rysownik',
      en: '/kurs/fotografik-wirtualny-rysownik',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 21,
    },
  },
  ServiceOffer29: {
    slug: {
      pl: '/kurs/grafika-3d-architekt',
      en: '/kurs/grafika-3d-architekt',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 43,
    },
  },
  ServiceOffer30: {
    slug: {
      pl: '/logiczna-przygoda-minecraft-polkolonie',
      en: '/logiczna-przygoda-minecraft-polkolonie',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 36,
    },
  },
  ServiceOffer31: {
    slug: {
      pl: '/grafika-komputerowa-online-instagrafik-polkolonie',
      en: '/grafika-komputerowa-online-instagrafik-polkolonie',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 37,
    },
  },
  ServiceOffer32: {
    slug: {
      pl: '/angielski-dla-dziecka-lets-talk-polkolonie',
      en: '/angielski-dla-dziecka-lets-talk-polkolonie',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 38,
    },
  },
  ServiceOffer33: {
    slug: {
      pl: '/matematyka-dla-dzieci-minecraft-polkolonie',
      en: '/matematyka-dla-dzieci-minecraft-polkolonie',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 39,
    },
  },
  ServiceOffer34: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-polski-MAX',
      en: '/course/eighth-grade-exam-polish-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 30,
    },
  },
  ServiceOffer35: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-matematyka-MAX',
      en: '/course/eighth-grade-exam-maths-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 28,
    },
  },
  ServiceOffer36: {
    slug: {
      pl: '/kurs/egzamin-osmoklasisty-angielski-MAX',
      en: '/course/eighth-grade-exam-english-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 29,
    },
  },
  ServiceOffer37: {
    slug: {
      pl: '/kurs/matura-angielski-MAX',
      en: '/course/matura-exam-english-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 55,
    },
  },
  ServiceOffer38: {
    slug: {
      pl: '/kurs/matura-matematyka-MAX',
      en: '/course/matura-exam-maths-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 56,
    },
  },
  ServiceOffer39: {
    slug: {
      pl: '/kurs/matura-polski-MAX',
      en: '/course/matura-exam-polish-MAX',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 58,
    },
  },
  ServiceOffer41: {
    slug: {
      pl: '/kurs/jak-stworzyc-gre-2D-w-Godot-Engine',
      en: '/course/how-to-create-game-with-godot-engine',
    },
    component: 'views/ServiceOffer.vue',
    props: {
      type: 'group',
      id: 41,
    },
  },
  EditPin: {
    slug: {
      pl: '/profiles/edit-pin/:id',
      en: '/profiles/edit-pin/:id',
    },
    component: 'views/Pins/EditPin.vue',
  },
  NewPin: {
    slug: {
      pl: '/profiles/new-pin',
      en: '/profiles/new-pin',
    },
    component: 'views/Pins/NewPin.vue',
  },
  EnrollPaymentSuccess: {
    slug: {
      pl: '/enroll/payment/success',
      en: '/enroll/payment/success',
    },
    component: 'views/EnrollPaymentSuccess.vue',
  },
  EnrollPaymentFailed: {
    slug: {
      pl: '/enroll/payment/failed',
      en: '/enroll/payment/failed',
    },
    component: 'views/EnrollPaymentFailed.vue',
  },
  EnrollContinuesSuccess: {
    slug: {
      pl: '/enroll/continues/success',
      en: '/enroll/continues/success',
    },
    component: 'views/EnrollContinuesSuccess.vue',
  },
  EnrollContinues: {
    slug: {
      pl: '/enroll/continues/:service/:profile/:isCc/:serviceConfigurationId?',
      en: '/enroll/continues/:service/:profile/:isCc/:serviceConfigurationId?',
    },
    component: 'views/EnrollContinues.vue',
  },
  Enroll1: {
    slug: {
      pl: '/enroll/:type/:id',
      en: '/enroll/:type/:id',
    },
    component: 'views/Enroll.vue',
    props: true,
  },
  Enroll2: {
    slug: {
      pl: '/enroll-individual/:type/:sId/:id',
      en: '/enroll-individual/:type/:sId/:id',
    },
    component: 'views/EnrollIndividual.vue',
  },
  PaymentForm: {
    slug: {
      pl: '/payment-form',
      en: '/payment-form',
    },
    component: 'views/PaymentForm.vue',
  },
  PaymentIndividualNew: {
    slug: {
      pl: '/payment/individual-new/:token',
      en: '/payment/individual-new/:token',
    },
    component: 'views/PaymentIndividualNew.vue',
  },
  PaymentServiceGroup: {
    slug: {
      pl: '/payment/group/:service/:course/:profile/:billingId?/:isOverdue?',
      en: '/payment/group/:service/:course/:profile/:billingId?/:isOverdue?',
    },
    component: 'views/PaymentServiceGroup.vue',
  },
  PaymentServiceGroupTurbo: {
    slug: {
      pl: '/payment/group-turbo/:service/:course/:profile/:billingId?/:isOverdue?',
      en: '/payment/group-turbo/:service/:course/:profile/:billingId?/:isOverdue?',
    },
    component: 'views/PaymentServiceGroupTurbo.vue',
  },
  PaymentPage: {
    slug: {
      pl: '/payment/:type',
      en: '/payment/:type',
    },
    props: true,
    component: 'views/PaymentPage.vue',
  },
  // PaymentPageEnrollAfterTrial: {
  //   slug: {
  //     pl: '/payment/individual',
  //     en: '/payment/individual',
  //   },
  //   props: true,
  //   component: 'views/PaymentPageEnrollAfterTrial.vue',
  // },
  PaymentResult: {
    slug: {
      pl: '/payment/individual/result',
      en: '/payment/individual/result',
    },
    component: 'views/PaymentResult.vue',
  },
  ProfileAddSuccess: {
    slug: {
      pl: '/profile-add-success',
      en: '/profile-add-success',
    },
    component: 'views/ProfileAddSuccess.vue',
  },
  WelcomeScreen: {
    slug: {
      pl: '/profiles/welcome-screen',
      en: '/profiles/welcome-screen',
    },
    component: 'views/Pins/WelcomeScreen.vue',
  },
  SetPinUser: {
    slug: {
      pl: '/profiles/set-pin-user',
      en: '/profiles/set-pin-user',
    },
    component: 'views/Pins/SetPinUser.vue',
  },
  Notifications: {
    slug: {
      pl: '/notifications',
      en: '/notifications',
    },
    component: 'views/Notifications.vue',
  },
  FirstLogin: {
    slug: {
      pl: '/first-login',
      en: '/first-login',
    },
    component: 'views/firstLoginTeacher/FirstLogin.vue',
  },
  BasicForm: {
    slug: {
      pl: '/first-login/basic',
      en: '/first-login/basic',
    },
    component: 'views/firstLoginTeacher/BasicForm.vue',
  },
  EducationForm: {
    slug: {
      pl: '/first-login/education',
      en: '/first-login/education',
    },
    component: 'views/firstLoginTeacher/EducationForm.vue',
  },
  ExperienceForm: {
    slug: {
      pl: '/first-login/experience',
      en: '/first-login/experience',
    },
    component: 'views/firstLoginTeacher/ExperienceForm.vue',
  },
  CertificatesForm: {
    slug: {
      pl: '/first-login/certificates',
      en: '/first-login/certificates',
    },
    component: 'views/firstLoginTeacher/CertificatesForm.vue',
  },
  LessonsAvailability: {
    slug: {
      pl: '/lessons-availability',
      en: '/lessons-availability',
    },
    component: 'views/firstLoginTeacher/LessonsAvailability.vue',
  },
  LessonsForm: {
    slug: {
      pl: '/lessons-availability/lessons',
      en: '/lessons-availability/lessons',
    },
    component: 'views/firstLoginTeacher/LessonsForm.vue',
  },
  AvailabilityForm: {
    slug: {
      pl: '/lessons-availability/availability',
      en: '/lessons-availability/availability',
    },
    component: 'views/firstLoginTeacher/AvailabilityForm.vue',
  },
  Media: {
    slug: {
      pl: '/media',
      en: '/media',
    },
    component: 'views/firstLoginTeacher/Media.vue',
  },
  Photo: {
    slug: {
      pl: '/media/photo',
      en: '/media/photo',
    },
    component: 'views/firstLoginTeacher/Photo.vue',
  },
  Video: {
    slug: {
      pl: '/media/video',
      en: '/media/video',
    },
    component: 'views/firstLoginTeacher/Video.vue',
  },
  VideoRecord: {
    slug: {
      pl: '/media/video/record',
      en: '/media/video/record',
    },
    component: 'views/firstLoginTeacher/VideoRecord.vue',
  },
  Summary: {
    slug: {
      pl: '/first-login/summary',
      en: '/first-login/summary',
    },
    component: 'views/firstLoginTeacher/Summary.vue',
  },
  SummaryResult: {
    slug: {
      pl: '/first-login/summary/result',
      en: '/first-login/summary/result',
    },
    component: 'views/firstLoginTeacher/SummaryResult.vue',
  },
  Candidate: {
    slug: {
      pl: '/first-login/candidate/:id',
      en: '/first-login/candidate/:id',
    },
    component: 'views/firstLoginTeacher/Candidate.vue',
  },
  DashboardTypography: {
    slug: {
      pl: '/typography',
      en: '/typography',
    },
    component: 'views/dashboard/DashboardTypography.vue',
  },
  Avatars: {
    slug: {
      pl: '/avatar',
      en: '/avatar',
    },
    component: 'views/Avatars.vue',
  },
  LoginTeacher: {
    slug: {
      pl: '/login-teacher',
      en: '/login-teacher',
    },
    component: 'views/LoginTeacher.vue',
  },
  StrapiShortUrls: {
    slug: {
      pl: '/m/:url',
      en: '/m/:url',
    },
    component: 'views/StrapiShortUrls.vue',
  },
  DashboardQuizStart1: {
    slug: {
      pl: '/dashboard/:courseId/quizz/:lessonId',
      en: '/dashboard/:courseId/quizz/:lessonId',
    },
    component: 'views/dashboard/DashboardQuizStart.vue',
  },
  DashboardQuizStart2: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId/quizz/:lessonId',
      en: '/dashboard/profile/:profileId/:courseId/quizz/:lessonId',
    },
    component: 'views/dashboard/DashboardQuizStart.vue',
  },
  DashboardQuizStart3: {
    slug: {
      pl: '/dashboard/my-profile/:courseId/quizz/:lessonId',
      en: '/dashboard/my-profile/:courseId/quizz/:lessonId',
    },
    component: 'views/dashboard/DashboardQuizStart.vue',
  },
  DashboardQuizStart4: {
    slug: {
      pl: '/dashboard/:courseId/detail/:lessonId',
      en: '/dashboard/:courseId/detail/:lessonId',
    },
    component: 'views/dashboard/DashboardQuizStart.vue',
  },
  DashboardMyProfile: {
    slug: {
      pl: '/dashboard/my-profile',
      en: '/dashboard/my-profile',
    },
    component: 'views/dashboard/Dashboard.vue',
  },
  DashboardMyProfileLessonDetail: {
    slug: {
      pl: '/lesson/:lessonId(\\d+)?',
      en: '/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardMyProfileLessonDetailRate: {
    slug: {
      pl: '/rate',
      en: '/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardMyProfileHomeCourseId: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/detail',
      en: '/dashboard/my-profile/:courseId(\\d+)?/detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdLessonDetail: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/detail/lesson/:lessonId(\\d+)?',
      en: '/dashboard/my-profile/:courseId(\\d+)?/detail/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardMyProfileHomeCourseIdLessonDetailRate: {
    slug: {
      pl: '/dashboard/my-profile/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/my-profile/lesson/:lessonId(\\d+)?/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardMyProfileHomeCourseIdCalendar: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/calendar',
      en: '/dashboard/my-profile/:courseId(\\d+)?/calendar',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdHomework: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/homework',
      en: '/dashboard/my-profile/:courseId(\\d+)?/homework',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdProgress: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/progress',
      en: '/dashboard/my-profile/:courseId(\\d+)?/progress',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdQuizz: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/quizz',
      en: '/dashboard/my-profile/:courseId(\\d+)?/quizz',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdMaterials: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/materials',
      en: '/dashboard/my-profile/:courseId(\\d+)?/materials',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
    props: true,
  },
  DashboardLessonMaterial1: {
    slug: {
      pl: '/lesson/:courseProgrammeId(\\d+)?',
      en: '/lesson/:courseProgrammeId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonMaterial.vue',
  },
  DashboardMyProfileHomeCourseIdCourseDetail: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/course-detail',
      en: '/dashboard/my-profile/:courseId(\\d+)?/course-detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdCourseProgram: {
    slug: {
      pl: '/dashboard/my-profile/:courseId(\\d+)?/course-program',
      en: '/dashboard/my-profile/:courseId(\\d+)?/course-program',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardMyProfileHomeCourseIdAboutTeacher: {
    slug: {
      pl: '/about-teacher',
      en: '/about-teacher',
    },
    component: 'views/dashboard/DashboardAboutTeacher.vue',
  },
  DashboardPaymentsSummaryClient1: {
    slug: {
      pl: '/payment-summary/:type',
      en: '/payment-summary/:type',
    },
    component: 'views/dashboard/DashboardPaymentsSummaryClient.vue',
  },
  Dashboard: {
    slug: {
      pl: '/dashboard',
      en: '/dashboard',
    },
    props: {
      access: ['tutor', 'client', 'student'],
    },
    component: 'views/dashboard/Dashboard.vue',
  },
  DashboardAvatars: {
    slug: {
      pl: '/dashboard/change-avatar',
      en: '/dashboard/change-avatar',
    },
    component: 'views/dashboard/DashboardAvatars.vue',
  },
  DashboardPaymentsSummaryClient2: {
    slug: {
      pl: '/dashboard/payment-summary/:type',
      en: '/dashboard/payment-summary/:type',
    },
    component: 'views/dashboard/DashboardPaymentsSummaryClient.vue',
  },
  ProfileSettings: {
    slug: {
      pl: '/dashboard/profile-settings/:id',
      en: '/dashboard/profile-settings/:id',
    },
    component: 'views/ProfileSettings.vue',
  },
  Settlements: {
    slug: {
      pl: '/dashboard/settlements',
      en: '/dashboard/settlements',
    },
    component: 'views/Settlements.vue',
  },
  Billings: {
    slug: {
      pl: '/dashboard/billings',
      en: '/dashboard/billings',
    },
    component: 'views/dashboard/DashboardBillings.vue',
  },
  DashboardLessonDetail: {
    slug: {
      pl: '/dashboard/lesson/:lessonId(\\d+)?/:type?',
      en: '/dashboard/lesson/:lessonId(\\d+)?/:type?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardLessonDetailRate: {
    slug: {
      pl: '/dashboard/lesson/:lessonId(\\d+)?/:type?/rate',
      en: '/dashboard/lesson/:lessonId(\\d+)?/:type?/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardHome: {
    slug: {
      pl: '/dashboard',
      en: '/dashboard',
    },
    component: 'views/dashboard/DashboardHome.vue',
  },
  DashboardHomeCourseId: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?',
      en: '/dashboard/:courseId(\\d+)?',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdLessonDetail: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?',
      en: '/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardHomeCourseIdLessonDetailRate: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardHomeCourseIdDetail: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/detail',
      en: '/dashboard/:courseId(\\d+)?/detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCoursesArchived: {
    slug: {
      pl: '/dashboard/archived',
      en: '/dashboard/archived',
    },
    component: 'views/dashboard/DashboardCoursesArchived.vue',
  },
  DashboardHomeCourseIdCalendar: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/calendar',
      en: '/dashboard/:courseId(\\d+)?/calendar',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdHomework: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/homework',
      en: '/dashboard/:courseId(\\d+)?/homework',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdProgress: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/progress',
      en: '/dashboard/:courseId(\\d+)?/progress',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdQuizz: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/quizz',
      en: '/dashboard/:courseId(\\d+)?/quizz',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdMaterials: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/materials',
      en: '/dashboard/:courseId(\\d+)?/materials',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdCourseDetail: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/course-detail',
      en: '/dashboard/:courseId(\\d+)?/course-detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdCourseProgram: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/course-program',
      en: '/dashboard/:courseId(\\d+)?/course-program',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardHomeCourseIdAboutTeacher: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?/about-teacher',
      en: '/dashboard/:courseId(\\d+)?/about-teacher',
    },
    component: 'views/dashboard/DashboardAboutTeacher.vue',
  },
  DashboardProfileLoaderId: {
    slug: {
      pl: '/dashboard',
      en: '/dashboard',
    },
    component: 'views/dashboard/loaders/ProfileLoader.vue',
  },
  DashboardProfileLoaderIdDashboardHomeStudent: {
    slug: {
      pl: '/dashboard/profile/:profileId',
      en: '/dashboard/profile/:profileId',
    },
    component: 'views/dashboard/loaders/ProfileLoader.vue',
  },
  DashboardProfileLoaderIdEvaluation: {
    slug: {
      pl: '/dashboard/profile/:profileId/evaluation/:diplomaId',
      en: '/dashboard/profile/:profileId/evaluation/:diplomaId',
    },
    component: 'views/dashboard/DashboardEvaluation.vue',
  },
  DashboardProfileLoaderIdDiploma: {
    slug: {
      pl: '/dashboard/profile/:profileId/diploma',
      en: '/dashboard/profile/:profileId/diploma',
    },
    component: 'views/dashboard/DashboardDiploma.vue',
  },
  DashboardProfileLoaderIdLessonDetail: {
    slug: {
      pl: '/dashboard/lesson/:lessonId(\\d+)?',
      en: '/dashboard/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardProfileLoaderIdLessonDetailRate: {
    slug: {
      pl: '/dashboard/rate',
      en: '/dashboard/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardProfileLoaderIdCourseId: {
    slug: {
      pl: '/dashboard/:courseId(\\d+)?',
      en: '/dashboard/:courseId(\\d+)?',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdLessonDetail: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardProfileLoaderIdCourseIdLessonDetailRate: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardProfileLoaderHomeCourseIdDetail: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/detail',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdDetail2: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?',
    },
    component: 'views/dashboard/DashboardDetail.vue',
  },
  DashboardProfileLoaderIdCourseIdCalendar: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/calendar',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/calendar',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdHomework: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/homework',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/homework',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdProgress: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/progress',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/progress',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdQuizz: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/quizz',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/quizz',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdMaterials: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/materials',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/materials',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdCourseDetail: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/course-detail',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/course-detail',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseProgram: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/course-program',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/course-program',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardProfileLoaderIdCourseIdAboutTeacher: {
    slug: {
      pl: '/dashboard/profile/:profileId/:courseId(\\d+)?/about-teacher',
      en: '/dashboard/profile/:profileId/:courseId(\\d+)?/about-teacher',
    },
    component: 'views/dashboard/loaders/CourseLoader.vue',
  },
  DashboardTeacherGroup: {
    slug: {
      pl: '/dashboard/group',
      en: '/dashboard/group',
    },
    component: 'views/dashboard/DashboardTeacherGroup.vue',
  },
  DashboardTeacherGroupCalendar: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/calendar',
      en: '/dashboard/group/:groupId(\\d+)?/calendar',
    },
    component: 'views/dashboard/loaders/GroupLoader.vue',
  },
  DashboardTeacherGroupDetail: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?',
      en: '/dashboard/group/:groupId(\\d+)?',
    },
    component: 'views/dashboard/loaders/GroupLoader.vue',
  },
  DashboardTeacherGroupHomework: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/homework',
      en: '/dashboard/group/:groupId(\\d+)?/homework',
    },
    component: 'views/dashboard/loaders/GroupLoader.vue',
  },
  DashboardTeacherGroupStudents: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/students',
      en: '/dashboard/group/:groupId(\\d+)?/students',
    },
    component: 'views/dashboard/loaders/GroupLoader.vue',
  },
  DashboardTeacherGroupMaterials: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/media',
      en: '/dashboard/group/:groupId(\\d+)?/media',
    },
    component: 'views/dashboard/loaders/GroupLoader.vue',
  },
  DashboardTeacherGroupLessonDetail: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?',
      en: '/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardTeacherGroupLessonDetailRate: {
    slug: {
      pl: '/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/group/:groupId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
    },
    props: {
      name: 'DashboardLessonDetailRate',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardTeacherIndividual: {
    slug: {
      pl: '/dashboard/individual',
      en: '/dashboard/individual',
    },
    component: 'views/dashboard/DashboardTeacherIndividual.vue',
  },
  DashboardTeacherIndividualDetail: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?',
      en: '/dashboard/individual/:individualId(\\d+)?',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualCalendar: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/calendar',
      en: '/dashboard/individual/:individualId(\\d+)?/calendar',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualMaterials: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/materials',
      en: '/dashboard/individual/:individualId(\\d+)?/materials',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardLessonMaterial2: {
    slug: {
      pl: '/ta',
      en: '/ta',
    },
    component: 'views/dashboard/DashboardLessonMaterial.vue',
  },
  DashboardLessonMaterial3: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?',
      en: '/dashboard/individual/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonMaterial.vue',
  },
  DashboardTeacherIndividualMedia: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/media',
      en: '/dashboard/individual/:individualId(\\d+)?/media',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualHomework: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/homework',
      en: '/dashboard/individual/:individualId(\\d+)?/homework',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualLessonDetail: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?',
      en: '/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardTeacherIndividualLessonDetailRate: {
    slug: {
      pl: '/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/individual/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
    },
    component: 'views/dashboard/DashboardLessonRate.vue',
  },
  DashboardTeacherTrialLesson: {
    slug: {
      pl: '/dashboard/trial-lesson',
      en: '/dashboard/trial-lesson',
    },
    component: 'views/dashboard/DashboardTeacherTrialLesson.vue',
  },
  DashboardTeacherTrialLesson2: {
    slug: {
      pl: '/dashboard/trial-lesson/lesson/:lessonId(\\d+)?',
      en: '/dashboard/trial-lesson/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardTeacherEvaluations: {
    slug: {
      pl: '/dashboard/evaluations',
      en: '/dashboard/evaluations',
    },
    component: 'views/dashboard/DashboardTeacherEvaluations.vue',
  },
  DashboardEvaluation: {
    slug: {
      pl: '/dashboard/evaluation/:diplomaId',
      en: '/dashboard/evaluation/:diplomaId',
    },
    component: 'views/dashboard/DashboardEvaluation.vue',
  },
  DashboardDiploma: {
    slug: {
      pl: '/dashboard/diploma',
      en: '/dashboard/diploma',
    },
    component: 'views/dashboard/DashboardDiploma.vue',
  },
  EnrollSuccess: {
    slug: {
      pl: '/enroll/success',
      en: '/enroll/success',
    },
    component: 'views/EnrollSuccess.vue',
  },
  Messenger: {
    slug: {
      pl: '/messenger',
      en: '/messenger',
    },
    component: 'views/Messenger.vue',
  },
  Settings: {
    slug: {
      pl: '/settings',
      en: '/settings',
    },
    component: 'views/Settings.vue',
  },
  NoProfilesForService: {
    slug: {
      pl: '/no-profiles-for-services',
      en: '/no-profiles-for-services',
    },
    component: 'views/NoProfilesForService.vue',
  },
  TeacherPayments: {
    slug: {
      pl: '/teacher/payments',
      en: '/teacher/payments',
    },
    component: 'views/teacher/TeacherPayments.vue',
  },
  TeacherData: {
    slug: {
      pl: '/teacher/personal-data',
      en: '/teacher/personal-data',
    },
    component: 'views/teacher/TeacherData.vue',
  },
  TeacherProfileEdit: {
    slug: {
      pl: '/teacher/profile-edit',
      en: '/teacher/profile-edit',
    },
    component: 'views/teacher/TeacherProfileEdit.vue',
  },
  EnrollWithoutAccount: {
    slug: {
      pl: '/enroll-without-account',
      en: '/enroll-without-account',
    },
    component: 'views/EnrollWithoutAccount.vue',
  },
  EnrollWithoutAccountWithService: {
    slug: {
      pl: '/enroll-without-account/:serviceId',
      en: '/enroll-without-account/:serviceId',
    },
    component: 'views/EnrollWithoutAccount.vue',
  },
  EnrollCompleteData: {
    slug: {
      pl: '/enroll-complete-data',
      en: '/enroll-complete-data',
    },
    component: 'views/EnrollCompleteData.vue',
  },
  EnrollOTP: {
    slug: {
      pl: '/enroll-otp',
      en: '/enroll-otp',
    },
    component: 'views/EnrollOTP.vue',
  },
  Password: {
    slug: {
      pl: '/password',
      en: '/password',
    },
    component: 'views/Password.vue',
  },
  EnrollChooseProfile: {
    slug: {
      pl: '/enroll-choose-profile',
      en: '/enroll-choose-profile',
    },
    component: 'views/EnrollChooseProfile.vue',
  },
  EnrollPin: {
    slug: {
      pl: '/enroll-pin/:profileId',
      en: '/enroll-pin/:profileId',
    },
    component: 'views/EnrollPin.vue',
  },
  EnrollVerifyProfileOtp: {
    slug: {
      pl: '/enroll-verify-profile-otp',
      en: '/enroll-verify-profile-otp',
    },
    component: 'views/EnrollVerifyProfileOtp.vue',
  },
  EnrollPhoneNumberExist: {
    slug: {
      pl: '/enroll-phone-number-exist',
      en: '/enroll-phone-number-exist',
    },
    component: 'views/EnrollPhoneNumberExist.vue',
  },
  ConfirmReservation: {
    slug: {
      pl: '/confirm-reservation/:reservationType/:tokenId',
      en: '/confirm-reservation',
    },
    props: {
      name: 'ConfirmReservation',
    },
    component: 'views/ConfirmReservation.vue',
  },
  EnrollAfterTrial: {
    slug: {
      pl: '/individual/enroll-after-trial/:courseId?',
      en: '/individual/enroll-after-trial/:courseId?',
    },
    component: 'views/EnrollAfterTrial.vue',
  },
  ServiceLeadOffer: {
    slug: {
      pl: '/lp/:serviceName',
      en: '/lp/:serviceName',
    },
    component: 'views/ServiceLeadOffer.vue',
  },
  ContinueRegister: {
    slug: {
      pl: '/continue-register',
      en: '/continue-register',
    },
    component: 'views/ContinueRegister.vue',
  },
  Onboarding: {
    slug: {
      pl: '/onboarding',
      en: '/onboarding',
    },
    component: 'views/onboarding/Onboarding.vue',
  },
  LeadOffer: {
    slug: {
      pl: '/lead-offer',
      en: '/lead-offer',
    },
    component: 'views/LeadOfferNew.vue',
  },
  MaturaOffer: {
    slug: {
      pl: '/matura',
      en: '/matura',
    },
    component: 'views/MaturaOffer.vue',
  },
  ServiceOffer: {
    slug: {
      pl: '/offer/group/:id',
      en: '/offer/group/:id',
    },
    component: 'views/ServiceOffer.vue',
  },
  Referral: {
    slug: {
      pl: '/programpolecajacy',
      en: '/referral',
    },
    component: 'views/Referral.vue',
    props: true,
  },
  ReferralInvited: {
    slug: {
      pl: '/programpolecajacy/:promoCode',
      en: '/referral/:promoCode',
    },
    component: 'views/ReferralInvited.vue',
    props: true,
  },
  CookiePolicy: {
    slug: {
      pl: '/cookie-policy',
      en: '/cookie-policy',
    },
    component: 'views/CookiePolicy.vue',
  },
  DashboardReferralSummary: {
    slug: {
      pl: '/referral-summary',
      en: '/referral-summary',
    },
    component: 'views/dashboard/DashboardReferralSummary.vue',
  },
  AskAboutServiceForm: {
    slug: {
      pl: '/export/ask-about-service-form',
      en: '/export/ask-about-service-form',
    },
    component: 'views/export/AskAboutServiceForm.vue',
  },
  SchoolsMap: {
    slug: {
      pl: '/schools-map',
      en: '/schools-map',
    },
    component: 'views/SchoolsMap.vue',
  },
  DocumentsTutorePoland: {
    slug: {
      pl: '/dokumenty-tutore-poland',
      en: '/documents-tutore-poland',
    },
    component: 'views/DocumentsTutorePoland.vue',
  },
  DocumentsTutoreSmall: {
    slug: {
      pl: '/dokumenty-tutore',
      en: '/documents-tutore',
    },
    component: 'views/DocumentsTutoreSmall.vue',
  },
  DocumentsSplit: {
    slug: {
      pl: '/dokumenty-spolka',
      en: '/documents-company',
    },
    component: 'views/DocumentsSplit.vue',
  },
  IndividualTrial: {
    slug: {
      pl: '/individual/trial/:courseId?',
      en: '/individual/trial/:courseId?',
    },
    component: 'views/oneByOne/IndividualTrial.vue',
  },
  IndividualEnrollSuccess: {
    slug: {
      pl: '/individual/success',
      en: '/individual/success',
    },
    component: 'views/oneByOne/IndividualEnrollSuccess.vue',
  },
  IndividualProceed: {
    slug: {
      pl: '/individual/proceed',
      en: '/individual/proceed',
    },
    component: 'views/oneByOne/IndividualProceed.vue',
  },
  IndividualBuy: {
    slug: {
      pl: '/individual/buy/:courseId?',
      en: '/individual/buy/:courseId?',
    },
    component: 'views/oneByOne/IndividualBuy.vue',
  },
  ReservationCancelledLesson: {
    slug: {
      pl: '/dashboard/individual-new/lesson/reservation/lesson/:lessonId/profile/:profileId(\\d+)?',
      en: '/dashboard/individual-new/lesson/reservation/lesson/:lessonId/profile/:profileId(\\d+)?',
    },
    component: 'views/dashboard/oneByOne/ReservationCancelledLesson.vue',
  },
  IndividualPaymentFinished: {
    slug: {
      pl: '/individual/finished/:statusType?',
      en: '/individual/finished/:statusType?',
    },
    component: 'views/oneByOne/IndividualPaymentFinish.vue',
  },
  WelcomeScreenTeacher: {
    slug: {
      pl: '/welcome-screen-teacher',
      en: '/welcome-screen-teacher',
    },
    component: 'views/WelcomeScreenTeacher.vue',
  },
  RegisterTeacherResult: {
    slug: {
      pl: '/register/teacher/success',
      en: '/register/teacher/success',
    },
    component: 'views/RegisterTeacherResult.vue',
  },
  DashboardTeacherIndividualNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?',
      en: '/dashboard/individual-new/:individualId(\\d+)?',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualCalendarNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/calendar',
      en: '/dashboard/individual-new/:individualId(\\d+)?/calendar',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualMaterialsNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/materials',
      en: '/dashboard/individual-new/:individualId(\\d+)?/materials',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardLessonMaterial3New: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?',
      en: '/dashboard/individual-new/:individualId(\\d+)?/materials/:lessonNumber(\\d+)?/:courseProgrammeId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonMaterial.vue',
  },
  DashboardTeacherIndividualMediaNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/media',
      en: '/dashboard/individual-new/:individualId(\\d+)?/media',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualHomeworkNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/homework',
      en: '/dashboard/individual-new/:individualId(\\d+)?/homework',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualStudentNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/student-profile',
      en: '/dashboard/individual-new/:individualId(\\d+)?/student-profile',
    },
    component: 'views/dashboard/DashboardTeacherIndividualDetail.vue',
  },
  DashboardTeacherIndividualLessonDetailNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?',
      en: '/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?',
    },
    component: 'views/dashboard/DashboardLessonDetail.vue',
  },
  DashboardTeacherIndividualLessonDetailRateNew: {
    slug: {
      pl: '/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
      en: '/dashboard/individual-new/:individualId(\\d+)?/lesson/:lessonId(\\d+)?/rate',
    },
    component: 'views/dashboard/DashboardLessonRate.vue',
  },
  HolidayBreak: {
    slug: {
      pl: '/holiday-break/',
      en: '/holiday-break/',
    },
    component: 'views/HolidayBreak.vue',
  },
};
