
import { defineComponent } from 'vue';
import { mdiClose } from '@mdi/js';
import { CourseType } from '../../types/enum';

export default defineComponent({
  name: 'NotLoginDialog',
  components: {
    DialogCard: () => import('../../components/DialogCard.vue'),
  },
  data() {
    return {
      mdiClose,
    };
  },
  computed: {
    courseInformation() {
      return this.$store.state.common.courseInformation;
    },
  },
  methods: {
    goToLogin() {
      const { type, id, courseType, serviceId } = this.courseInformation;
      if (type === CourseType.INDIVIDUAL) {
        this.$store.commit(
          'common/setRedirectTo',
          `/${this.$nuxt.$i18n.locale}/enroll-individual/${type}/${serviceId}/${id}?courseType=${courseType}`,
        );
      } else {
        this.$store.commit(
          'common/setRedirectTo',
          `/${this.$nuxt.$i18n.locale}/enroll/${type}/${id}?courseType=${courseType}`,
        );
      }
      this.$router.push('/' + this.$root.$i18n.locale + '/login');
      this.$store.commit('common/setShowNotLoginDialog', false);
    },
    goToRegister() {
      this.$router.push('/' + this.$root.$i18n.locale + '/register');
      this.$store.commit('common/setShowNotLoginDialog', false);
    },
    goToEnrollWithoutAccount() {
      const { id, isAdult } = this.courseInformation;
      if (isAdult) {
        this.$router.push(
          `/${this.$nuxt.$i18n.locale}/enroll/group/${id}?courseType=trial`,
        );
        const service = [
          {
            serviceId: id,
          },
        ];
        this.$store.commit('common/setChoosenServices', service);
      } else {
        this.$router.push(`/enroll-without-account/${id}`);
        this.$store.commit('common/setCourseInformation', {});
      }

      this.$store.commit('common/setShowNotLoginDialog', false);
    },
  },
});
