import { ActionContext, ActionTree } from 'vuex';
import { RootStateStore } from '../rootState';
import axiosInstance from '../../plugins/axios';
import { MediaState } from './stateType';

const actions: ActionTree<MediaState, RootStateStore> = {
  // async fetchMedia ({
  //  commit
  // }: ActionContext<MediaState, unknown>): Promise<void> {
  //  try {
  //    const media = await axiosInstance
  //      .get('/tutors/media')
  //      .then(response => response.data)
  //    return commit('setMedia', media)
  //  } catch (error) {
  //    console.error(error)
  //    return Promise.reject(error)
  //  }
  // }
};

export default actions;
