var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{class:{
    'timer-component': _vm.$vuetify.breakpoint.mdAndUp,
    'timer-component-with-error': _vm.$vuetify.breakpoint.mdAndUp && _vm.isAlert,
    'timer-component-sm': _vm.$vuetify.breakpoint.smAndDown && !_vm.isAlert,
    'timer-component-sm-with-error': _vm.$vuetify.breakpoint.smAndDown && _vm.isAlert,
  },style:(_vm.showInformation || _vm.showMoreTime ? 'background-color: #f5f3fa;' : '')},[(_vm.showMoreTime)?_c('div',{class:[
      'reservation-time-block',
      {
        'reservation-time-block-md-and-up': _vm.$vuetify.breakpoint.mdAndUp,
        'reservation-time-block-sm': !_vm.$vuetify.breakpoint.mdAndUp,
      },
    ]},[_c('span',{staticStyle:{"font-weight":"600","color":"#1d1d4e"}},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.END_RESERVATION_TIME')))]),_vm._v(" "),_c('div',[_c('span',{staticStyle:{"font-size":"12px","color":"#1d1d4e"}},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.ADD')))]),_vm._v(" "),_c('t-btn',{staticClass:"ml-2",staticStyle:{"background-color":"#f5f3fa","color":"#823189","border":"1px solid #823189","font-size":"14px","width":"70px","height":"24px","border-radius":"50px"},attrs:{"data-button":"enroll-timer-add-two-minutes"},on:{"click":function($event){return _vm.addTime(2)}}},[_vm._v("\n        + 2 min\n      ")]),_vm._v(" "),_c('t-btn',{staticClass:"ml-2",staticStyle:{"background-color":"#f5f3fa","color":"#823189","border":"1px solid #823189","font-size":"14px","width":"70px","height":"24px","border-radius":"50px"},attrs:{"data-button":"enroll-timer-add-five-minutes"},on:{"click":function($event){return _vm.addTime(5)}}},[_vm._v("\n        + 5 min\n      ")])],1)]):_vm._e(),_vm._v(" "),(_vm.showInformation)?_c('div',{class:[
      'information-block',
      {
        'information-block-md-and-up': _vm.$vuetify.breakpoint.mdAndUp,
        'information-block-sm': !_vm.$vuetify.breakpoint.mdAndUp,
      },
    ]},[_c('span',{staticStyle:{"font-weight":"600","color":"#1d1d4e"}},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.RESERVATION_TIME')))]),_vm._v(" "),_c('span',{staticStyle:{"font-size":"12px","color":"#1d1d4e"}},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.HELD_RESERVATION')))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"circle"},[_c('v-progress-circular',{staticClass:"p3 w-3",attrs:{"rotate":-90,"size":54,"width":6,"value":(_vm.seconds / 300) * 100,"color":"primary"}},[_c('span',{staticClass:"c-blueblack"},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")])])],1)]):(_vm.showMoreTime)?_c('div',[_c('v-bottom-navigation',{staticClass:"timer-bottom-navigation",attrs:{"fixed":"","height":"200"}},[_c('v-row',{staticClass:"py-6 px-8"},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"close-icon",on:{"click":function($event){_vm.showMoreTime = false}}},[_vm._v("\n          "+_vm._s(_vm.mdiClose)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"w-3 c-blueblack h6 mb-4"},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.END_RESERVATION_TIME')))]),_vm._v(" "),_c('span',{staticClass:"c-blueblack p1"},[_vm._v(_vm._s(_vm.$t('ENROLL.TIMER.CONTINUE_RESERVATION')))])])],1),_vm._v(" "),_c('v-col',{staticClass:"pl-0 pr-2",attrs:{"cols":"6"}},[_c('t-btn',{staticClass:"buttons c-white",attrs:{"color":"primary","height":"40","block":""},on:{"click":function($event){return _vm.addTime(5)}}},[_vm._v("\n          + 5 min\n        ")])],1),_vm._v(" "),_c('v-col',{staticClass:"pr-0 pl-2",attrs:{"cols":"6"}},[_c('t-btn',{staticClass:"buttons c-primary",attrs:{"color":"secondary","block":""},on:{"click":function($event){return _vm.addTime(2)}}},[_vm._v("\n          + 2 min\n        ")])],1)],1)],1),_vm._v(" "),(_vm.showMoreTime)?_c('v-overlay',{attrs:{"z-index":"1","absolute":"","color":"#1d1d4e","opacity":"0.5"},on:{"click":function($event){_vm.showMoreTime = false}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }