
import { defineComponent } from 'vue';
import { mdiClose } from '@mdi/js';

export default defineComponent({
  name: 'EnrollTimer',
  data() {
    return {
      text: '',
      interval: 0,
      showInformation: true,
      showMoreTime: false,
      finishDate: 0,
      seconds: 0,
      mdiClose,
    };
  },
  computed: {
    isAlert(): boolean {
      return this.$store.state.common.isAlert;
    },
    counter(): number {
      return this.$store.state.common.counter;
    },
  },
  watch: {
    counter() {
      this.generateFinishTime();
    },
  },
  mounted() {
    // const seconds = sessionStorage.getItem('common/seconds');

    // this.seconds = seconds !== null ? Number(seconds) : 60;
    this.generateFinishTime();
    this.interval = window.setInterval(() => {
      this.generateMinutes();
      sessionStorage.setItem('common/seconds', this.generateSeconds());
    }, 1000);
  },
  methods: {
    generateFinishTime() {
      this.finishDate = new Date(
        new Date().getTime() + 1000 * 60 * 5,
      ).getTime();
      this.text = '05:00';
    },
    generateSeconds(): string {
      const now = new Date().getTime();
      const timeleft = this.finishDate - now;
      const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
      const s = minutes * 60 + seconds;
      this.seconds = s;
      return s.toString();
    },
    addTime(minutes: number) {
      this.finishDate = new Date(
        this.finishDate + 1000 * 60 * minutes,
      ).getTime();
    },
    stopInterval() {
      window.clearInterval(this.interval);
    },
    generateMinutes() {
      const now = new Date().getTime();
      const timeleft = this.finishDate - now;
      const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
      const minutesDisplay: string =
        minutes >= 10 ? minutes.toString() : `0${minutes.toString()}`;
      const secondsDisplay: string =
        seconds >= 10 ? seconds.toString() : `0${seconds.toString()}`;
      this.text = `${minutesDisplay}:${secondsDisplay}`;
      if (minutes <= 4 && seconds < 50) {
        this.showInformation = false;
      }
      if (minutes === 0 && seconds <= 30) {
        this.showMoreTime = true;
      } else if (seconds > 6) {
        this.showMoreTime = false;
      }
      if (minutes <= 0 && seconds <= 0) {
        this.stopInterval();
        sessionStorage.removeItem('common/showEnrollTimer');
        sessionStorage.removeItem('common/seconds');
        this.$store.commit('common/setShowEnrollTimer', false);
        this.$store.commit('common/setEnrollTimerStatus', 'timeIsUp');
      } else {
        this.$store.commit('common/setEnrollTimerStatus', null);
      }
    },
  },
});
